import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Breadcumb from "../master/Breadcumb";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nama: "",
      singkatan: "",
      active: true,
      spse: "",
      mode: "add",
    };
  }

  onChange(e) {
    const name = e.target.name;
    const tipe = e.target.type;
    let value = e.target.value;
    if (tipe === "checkbox") {
      value = e.target.checked;
    }
    this.setState({
      [name]: value,
    });
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      axios.get(`${process.env.REACT_APP_APIURL}opd/${this.props.match.params.id}`).then((response) => {
        this.setState({
          nama: response.data.opd.opd_nama,
          singkatan: response.data.opd.opd_singkatan,
          spse: response.data.opd.opd_spse,
          active: response.data.opd.opd_active == "1" ? true : false,
          mode: "edit",
        });
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    let formData = {
      nama: this.state.nama,
      singkatan: this.state.singkatan,
      spse: this.state.spse,
      active: this.state.active,
    };

    if (this.state.mode === "add") {
      axios.post(process.env.REACT_APP_APIURL + "opd", formData).then((response) => {
        this.props.history.push("/master/opd");
      });
    } else if (this.state.mode === "edit") {
      console.log("edit");
      axios.put(process.env.REACT_APP_APIURL + "opd/" + this.props.match.params.id, formData).then((response) => {
        console.log("sukses edit");
        this.props.history.push("/master/opd");
      });
    }
  }

  render() {
    return (
      <div className="container-fluid">
        <Breadcumb page_name="OPD" pages={["Data Master", this.state.mode == "add" ? "Form Tambah OPD" : "Form Edit OPD"]} />
        <div className="row">
          <div className="col-lg-6">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Form OPD</h4>
                <p className="text-muted mb-0">Silahkan lengkapi form berikut</p>
              </div>
              <div className="card-body">
                <div className="general-label">
                  <form onSubmit={(e) => this.onSubmit(e)}>
                    <div className="mb-3 row">
                      <label htmlFor="nama" className="col-sm-2 form-label align-self-center mb-lg-0">
                        Nama
                      </label>
                      <div className="col-sm-10">
                        <input type="text" className="form-control" name="nama" placeholder="Masukkan nama opd" value={this.state.nama} onChange={(e) => this.onChange(e)} />
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label htmlFor="singkatan" className="col-sm-2 form-label align-self-center mb-lg-0">
                        Singkatan
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          className="form-control"
                          name="singkatan"
                          placeholder="Masukkan singkatan opd"
                          value={this.state.singkatan}
                          onChange={(e) => this.onChange(e)}
                        />
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label htmlFor="spse" className="col-sm-2 form-label align-self-center mb-lg-0">
                        SPSE ID
                      </label>
                      <div className="col-sm-10">
                        <input type="text" className="form-control" name="spse" placeholder="Masukkan spse id" value={this.state.spse} onChange={(e) => this.onChange(e)} />
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label htmlFor="merk" className="col-sm-2 form-label align-self-center mb-lg-0">
                        Active
                      </label>
                      <div className="col-sm-10">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="active"
                            id="flexSwitchCheckDefault"
                            checked={this.state.active}
                            onChange={(e) => this.onChange(e)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-10 ms-auto">
                        <button type="submit" className="btn btn-primary" onClick={(e) => this.onSubmit(e)}>
                          Simpan
                        </button>
                        &nbsp;
                        <Link to="/master/opd" className="btn btn-danger">
                          Batal
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Form;
