import React, { Component } from "react";
import Chart from "react-apexcharts";

class Grafik extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {},
      series: [],
      opd: [],
    };
  }

  componentDidMount() {
    this.setState(
      {
        // opd: this.props.opd.sort(() => Math.random() - 0.5),
        opd: this.props.opd,
      },
      () => {
        this.renderChart();
      }
    );

    // console.log(this.props.opd);
  }

  renderChart() {
    // console.log(this.state.opd);
    this.setState({
      options: {
        labels: this.state.opd.map((e) => {
          return e.opd_singkatan;
        }),
        chart: {
          height: 345,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "30%",
          },
        },
        colors: ["#9fc1fa"],
        dataLabels: {
          enabled: false,
        },

        stroke: {
          show: true,
          width: 2,
        },
        yaxis: {
          labels: {
            offsetX: -12,
            offsetY: 0,
          },
        },
        grid: {
          borderColor: "#e0e6ed",
          strokeDashArray: 3,
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        legend: {
          show: false,
        },
        tooltip: {
          marker: {
            show: true,
          },
          x: {
            show: false,
          },
        },
        fill: {
          opacity: 1,
        },
      },
      series: [
        {
          name: "Anggaran",
          data: this.state.opd.map((e) => {
            return e.total;
          }),
        },
      ],
    });
  }

  render() {
    return (
      <div className="card">
        <div className="card-header">
          <div className="row align-items-center">
            <div className="col">
              <h4 className="card-title">Grafik Anggaran</h4>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="">
            <Chart type="bar" options={this.state.options} className="apex-charts" series={this.state.series} height="255" />
          </div>
        </div>
      </div>
    );
  }
}

export default Grafik;
