import { Component } from "react";
import { withRouter } from "react-router-dom";
import Cookies from "js-cookie";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    Object.keys(Cookies.get()).forEach(function (cookieName) {
      if (cookieName != "perpage" && cookieName != "satker") Cookies.remove(cookieName);
    });
    this.props.history.push("/auth");
  }
  render() {
    return null;
  }
}

export default withRouter(Index);
