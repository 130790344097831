import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import Breadcumb from "../master/Breadcumb";
import { bulan } from "../../Definisi";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
import moment from "moment";
import tempFileHandler from "express-fileupload/lib/tempFileHandler";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: undefined,
      keuangan: 0,
      fisik: 0,
      total: 0,
      bulan: moment().format("M"),
      mode: "add",
    };
  }

  onChange(e) {
    const name = e.target.name;
    // const tipe = e.target.type;
    let value = e.target.value;

    this.setState({
      [name]: value,
    });
  }

  componentDidMount() {
    axios.get(`${process.env.REACT_APP_APIURL}anggaran`, { headers: { Authorization: "Bearer " + Cookies.get("auth-token") } }).then((response) => {
      if (response.data.anggaran.length > 0) {
        this.setState({
          // apbd: response.data.anggaran[0].anggaran_murni,
          // apbdp: response.data.anggaran[0].anggaran_perubahan,
          total: +response.data.anggaran[0].anggaran_murni + +response.data.anggaran[0].anggaran_perubahan,
          // mode: "edit",
        });
      }
    });
    axios
      .get(`${process.env.REACT_APP_APIURL}realisasi/${this.props.match.params.id}`, { headers: { Authorization: "Bearer " + Cookies.get("auth-token") } })
      .then((response) => {
        if (response.data.realisasi) {
          this.setState({
            fisik: response.data.realisasi.realisasi_fisik,
            keuangan: response.data.realisasi.realisasi_keuangan,
            bulan: response.data.realisasi.realisasi_bulan,
            // total: +response.data.realisasi.realisasi_anggaran,
            mode: "edit",
          });
        }
      });
  }

  info(pesan, mode = "error") {
    var Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });

    Toast.fire({
      icon: mode,
      title: pesan,
    });
  }

  onSubmit() {
    let formData = {
      keuangan: this.state.keuangan,
      fisik: this.state.fisik,
      bulan: this.state.bulan,
      total: this.state.total,
    };
    if (this.props.match.params.id) {
      axios
        .put(process.env.REACT_APP_APIURL + "realisasi/" + this.props.match.params.id, formData, { headers: { Authorization: "Bearer " + Cookies.get("auth-token") } })
        .then((response) => {
          this.props.history.push("/realisasi");
        })
        .catch((reason) => {
          this.info("Input Data Error!<br />" + reason.response.data.message);
        });
    } else {
      axios
        .post(process.env.REACT_APP_APIURL + "realisasi", formData, { headers: { Authorization: "Bearer " + Cookies.get("auth-token") } })
        .then((response) => {
          this.props.history.push("/realisasi");
        })
        .catch((reason) => {
          this.info("Input Data Error!<br />" + reason.response.data.message);
        });
    }
  }

  render() {
    return (
      <div className="container-fluid">
        <Breadcumb page_name="Realisasi Anggaran" pages={["Realisasi Anggaran", this.state.mode == "add" ? "Form Tambah Realisasi" : "Form Edit Realisasi"]} />
        <div className="row">
          <div className="col-lg-4">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Form Realisasi</h4>
                <p className="text-muted mb-0">Silahkan lengkapi form berikut</p>
              </div>
              <div className="card-body">
                <div className="general-label">
                  <form onSubmit={() => this.onSubmit()}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label className="form-label" htmlFor="bulan">
                            Bulan
                          </label>
                          <select
                            name="bulan"
                            className="form-select"
                            aria-label="Default select example"
                            id="bulan"
                            value={this.state.bulan}
                            onChange={(e) => this.onChange(e)}
                          >
                            <option value="">Pilih Bulan</option>
                            {bulan.map((e, idx) => {
                              return (
                                <option value={e.id} key={e.id}>
                                  {e.nama_bulan}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label className="form-label" htmlFor="keuangan">
                            Keuangan
                          </label>
                          <NumberFormat
                            thousandSeparator={true}
                            value={this.state.keuangan}
                            className="form-control"
                            onValueChange={(values) => {
                              const { formattedValue, value } = values;
                              this.setState({ keuangan: value });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label className="form-label" htmlFor="fisik">
                            Fisik
                          </label>
                          <NumberFormat
                            thousandSeparator={true}
                            value={this.state.fisik}
                            className="form-control"
                            onValueChange={(values) => {
                              const { formattedValue, value } = values;
                              this.setState({ fisik: value });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label className="form-label" htmlFor="total">
                            TOTAL Anggaran
                          </label>
                          <NumberFormat thousandSeparator={true} value={this.state.total} className="form-control" readOnly />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="ms-auto">
                        <button type="button" className="btn btn-primary" onClick={() => this.onSubmit()}>
                          Simpan
                        </button>
                        &nbsp;
                        <Link to="/realisasi" className="btn btn-danger">
                          Batal
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Form);
