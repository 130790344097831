import React, { Component } from "react";

class ModalHapus extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="modal fade" id="modalHapus" tabIndex="-1" role="dialog" aria-labelledby="exampleModalDanger1" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header bg-danger">
              <h6 className="modal-title m-0 text-white" id="exampleModalDanger1">
                Konfirmasi
              </h6>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-3 text-center align-self-center">
                  <img src={process.env.REACT_APP_APIURL + "images/warning.png"} alt="" className="img-fluid" />
                </div>
                <div className="col-lg-9">
                  <h5>Apakah anda yakin ingin menghapus data ini?</h5>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-soft-secondary btn-sm" data-bs-dismiss="modal" onClick={() => this.props.onDelete()}>
                Hapus
              </button>
              <button type="button" className="btn btn-soft-danger btn-sm" data-bs-dismiss="modal">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalHapus;
