import Cookies from "js-cookie";
import React, { Component } from "react";
import NotificationBar from "./_notification";
import { Link, withRouter } from "react-router-dom";

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nama: "",
    };
  }

  componentDidMount() {
    if (!Cookies.get("nama")) this.props.history.push("/auth");
    this.setState({
      nama: Cookies.get("nama"),
    });
  }

  render() {
    return (
      <div className="topbar">
        <nav className="navbar-custom">
          <ul className="list-unstyled topbar-nav float-end mb-0">
            <li className="dropdown hide-phone">
              <a
                className="nav-link dropdown-toggle arrow-none waves-light waves-effect"
                data-bs-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <i data-feather="search" className="topbar-icon"></i>
              </a>

              <div className="dropdown-menu dropdown-menu-end dropdown-lg p-0">
                <div className="app-search-topbar">
                  <form action="#" method="get">
                    <input type="search" name="search" className="from-control top-search mb-0" placeholder="Type text..." />
                    <button type="submit">
                      <i className="ti-search"></i>
                    </button>
                  </form>
                </div>
              </div>
            </li>

            <li className="dropdown">
              <a
                className="nav-link dropdown-toggle waves-effect waves-light nav-user"
                data-bs-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <span className="ms-1 nav-user-name hidden-sm">{this.state.nama} </span>
                <img src={`${process.env.REACT_APP_APIURL}uploads/${Cookies.get("foto")}`} alt="profile-user" className="rounded-circle thumb-xs" />
              </a>
              <div className="dropdown-menu dropdown-menu-end">
                <a className="dropdown-item" href="#">
                  <i data-feather="user" className="align-self-center icon-xs icon-dual me-1"></i> Profile
                </a>
                <a className="dropdown-item" href="#">
                  <i data-feather="settings" className="align-self-center icon-xs icon-dual me-1"></i> Settings
                </a>
                <div className="dropdown-divider mb-0"></div>
                <Link className="dropdown-item" to="/logout">
                  <i data-feather="power" className="align-self-center icon-xs icon-dual me-1"></i> Logout
                </Link>
              </div>
            </li>
          </ul>

          <ul className="list-unstyled topbar-nav mb-0">
            <li>
              <button className="nav-link button-menu-mobile">
                <i data-feather="menu" className="align-self-center topbar-icon"></i>
              </button>
            </li>
            <li className="creat-btn">
              <div className="nav-link">
                <a className=" btn btn-sm btn-soft-primary" href="#" role="button">
                  <i className="fas fa-plus me-2"></i>New Task
                </a>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}

export default withRouter(Topbar);
