import React, { Component } from "react";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import axios from "axios";
import $ from "jquery";
import "datatables.net-bs5";

import "../plugins/datatables/dataTables.bootstrap5.min.css";
import "../plugins/datatables/buttons.bootstrap5.min.css";
import "../plugins/datatables/responsive.bootstrap4.min.css";
import { bulan } from "../../Definisi";
import Cookies from "js-cookie";
import moment from "moment";
import download from "js-file-download";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bagian: [],
      realisasi: [],
      anggaran_total: 0,
      anggaran_bagian: 0,
      bulan: moment().format("M"),
      id: "",
    };
  }

  onChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name === "bulan") {
          this.tableRender();
        }
      }
    );
  }

  componentDidMount() {
    this.tableRender();
    // console.log(moment().format("M"));
  }

  menuEdit(e) {
    if (Cookies.get("level") < 2) {
      return (
        <Link to={`/bagian/edit/${e.bagian_id}`}>
          <i className="ms-3 las la-pen text-secondary font-16"></i>
        </Link>
      );
    }
  }

  tableRender() {
    $("#row_callback").DataTable().destroy();
    axios.get(`${process.env.REACT_APP_APIURL}bagian/anggaran/${Cookies.get("tahun")}/${this.state.bulan}/${Cookies.get("opd")}`).then((response) => {
      this.setState(
        {
          bagian: response.data.bagian,
          realisasi: response.data.realisasi,
          anggaran_total: response.data.anggaran[0].anggaran_murni + +response.data.anggaran[0].anggaran_perubahan,
          anggaran_bagian: response.data.bagian.reduce((a, b) => +a + +b.anggaran_murni + +b.anggaran_perubahan, 0),
          selisih:
            response.data.anggaran[0].anggaran_murni +
            +response.data.anggaran[0].anggaran_perubahan -
            response.data.bagian.reduce((a, b) => +a + +b.anggaran_murni + +b.anggaran_perubahan, 0),
        },
        () => {
          $("#row_callback").DataTable();
        }
      );
    });
  }

  download() {
    axios
      .get(`${process.env.REACT_APP_APIURL}bagian/download/${Cookies.get("tahun")}/${this.state.bulan}/${Cookies.get("opd")}`, {
        headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
        responseType: "blob",
      })
      .then((response) => {
        let filename = response.headers["content-disposition"].split("filename=")[1].replaceAll('"', "");
        download(response.data, filename);
      });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="page-title-box">
              <div className="row">
                <div className="col">
                  <h4 className="page-title">Bidang / Bagian</h4>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">List Bagian</li>
                  </ol>
                </div>
                <div className="col-auto col-4 align-self-center">
                  <div className="mb-3 row">
                    <label htmlFor="bulan" className="col-sm-3 form-label align-self-center mb-lg-0">
                      Pilih Bulan
                    </label>
                    <div className="col-sm-5">
                      <select name="bulan" className="form-select" aria-label="Default select example" id="bulan" value={this.state.bulan} onChange={(e) => this.onChange(e)}>
                        {bulan.map((e, idx) => {
                          return (
                            <option value={e.id} key={e.id}>
                              {e.nama_bulan}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-sm-4">
                      <Link to={`/bagian/add`} className="btn btn-primary btn-sm add-file">
                        Tambah
                      </Link>
                      &nbsp;
                      <button className="btn btn-danger btn-sm add-file" onClick={() => this.download()}>
                        Download
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="row">
            <div className="col-12 mb-3">
              Total Anggaran: <NumberFormat value={this.state.anggaran_total} thousandSeparator={true} displayType="text" />
              &nbsp;- <NumberFormat value={this.state.anggaran_bagian} thousandSeparator={true} displayType="text" />
              &nbsp; = <NumberFormat value={this.state.selisih} thousandSeparator={true} displayType="text" />
              {this.state.selisih == this.state.anggaran_total ? "" : this.state.selisih > 0 ? " (Selisih)" : ""}
            </div>
          </div>
          <div className="col-12">
            <table
              id="row_callback"
              className="table table-striped table-bordered dt-responsive nowrap"
              style={{ borderCollapse: "collapse", borderSpacing: 0, width: "100%" }}
            >
              <thead>
                <tr>
                  <th width="3%" rowSpan="3">
                    No
                  </th>
                  <th rowSpan="3">Nama Bidang/Bagian</th>
                  <th className="text-end" rowSpan="3">
                    APBD
                  </th>
                  <th className="text-center" rowSpan="3">
                    APBD-P
                  </th>
                  <th className="text-center" rowSpan="3">
                    Total Anggaran
                  </th>
                  <th colSpan="3" className="text-center">
                    Realisasi {bulan.find((i) => i.id == this.state.bulan).pendek} {Cookies.get("tahun")}
                  </th>
                </tr>
                <tr>
                  <th className="text-center">Fisik</th>
                  <th className="text-center" colSpan="2">
                    Keuangan
                  </th>
                </tr>
                <tr>
                  <th className="text-center">%</th>
                  <th className="text-center">Rp</th>
                  <th className="text-center">%</th>
                </tr>
              </thead>
              <tbody>
                {this.state.bagian.map((e, idx) => {
                  let fisik = 0,
                    keuangan = 0,
                    persen_keuangan = 0;
                  let realisasi = this.state.realisasi.find((i) => i.realisasi_bagian === e.bagian_id);
                  if (realisasi) {
                    fisik = realisasi.realisasi_fisik;
                    keuangan = realisasi.realisasi_keuangan;
                    persen_keuangan = ((keuangan / (+e.anggaran_murni + +e.anggaran_perubahan)) * 100).toFixed(2);
                  }
                  return (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>
                        <Link to={`/realisasibagian/${e.bagian_id}/${Cookies.get("tahun")}`}>{e.bagian_nama}</Link>
                        {this.menuEdit(e)}
                      </td>
                      <td className="text-end">
                        <NumberFormat value={e.anggaran_murni} thousandSeparator={true} displayType="text" />
                      </td>
                      <td className="text-end">
                        <NumberFormat value={e.anggaran_perubahan} thousandSeparator={true} displayType="text" />
                      </td>
                      <td className="text-end">
                        <NumberFormat value={+e.anggaran_murni + +e.anggaran_perubahan} thousandSeparator={true} displayType="text" />
                      </td>
                      <td className="text-center">{fisik}</td>
                      <td className="text-end">
                        <NumberFormat value={keuangan} displayType={"text"} thousandSeparator={true} />
                      </td>
                      <td className="text-center">{persen_keuangan}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
