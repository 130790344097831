import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import jwt from "jsonwebtoken";
import Swal from "sweetalert2";
import moment from "moment";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      tahun: moment().format("YYYY"),
      pilihan_tahun: [],
    };
  }

  onChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
    });
  }

  componentDidMount() {
    axios.get(`${process.env.REACT_APP_APIURL}auth`).then((response) => {
      this.setState({
        pilihan_tahun: response.data.tahun,
      });
    });
  }

  onSubmit(e) {
    e.preventDefault();
    let formData = {
      username: this.state.username,
      password: this.state.password,
      tahun: this.state.tahun,
    };
    axios
      .post(process.env.REACT_APP_APIURL + "auth/login", formData)
      .then((response) => {
        Cookies.set("auth-token", response.headers.authorization);
        let res = jwt.decode(response.headers.authorization);
        Cookies.set("foto", response.data.foto);
        Cookies.set("opd_spse", response.data.opd_spse);
        Cookies.set("opd_nama", response.data.opd_nama);
        Cookies.set("opd", response.data.opd);
        Cookies.set("tahun", response.data.tahun);
        Cookies.set("nama", res.nama);
        Cookies.set("level", res.level);
        if (res.level == 2) {
          Cookies.set("bagian", response.data.bagian);
        }
        this.props.history.push("/dashboard");
      })
      .catch((reason) => {
        var Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        Toast.fire({
          icon: "error",
          title: "Login Error!<br />" + reason.response.data.message,
        });
      });
  }

  render() {
    return (
      <div className="container">
        <div className="row vh-100 d-flex justify-content-center">
          <div className="col-12 align-self-center">
            <div className="row">
              <div className="col-lg-5 mx-auto">
                <div className="card">
                  <div className="card-body p-0 auth-header-box">
                    <div className="text-center p-3">
                      <a href="#" className="logo logo-admin">
                        <img src={process.env.REACT_APP_APIURL + "images/logo-sm-dark.png"} height="100" alt="logo" className="auth-logo" />
                      </a>
                      <h4 className="mt-3 mb-1 fw-semibold text-white font-18">{process.env.REACT_APP_NAME}</h4>
                      <p className="text-muted  mb-0">Sign in to continue to {process.env.REACT_APP_NAME}.</p>
                    </div>
                  </div>
                  <div className="card-body p-0">
                    <div className="tab-content">
                      <div className="tab-pane active p-3" id="LogIn_Tab" role="tabpanel">
                        <form className="form-horizontal auth-form" onSubmit={(e) => this.onSubmit(e)}>
                          <div className="form-group mb-2">
                            <label className="form-label" htmlFor="username">
                              Username
                            </label>
                            <div className="input-group">
                              <input type="text" className="form-control" name="username" id="username" placeholder="Enter username" onChange={(e) => this.onChange(e)} />
                            </div>
                          </div>

                          <div className="form-group mb-2">
                            <label className="form-label" htmlFor="userpassword">
                              Password
                            </label>
                            <div className="input-group">
                              <input
                                type="password"
                                className="form-control"
                                name="password"
                                id="userpassword"
                                placeholder="Enter password"
                                onChange={(e) => this.onChange(e)}
                              />
                            </div>
                          </div>
                          <div className="form-group mb-2">
                            <label className="form-label" htmlFor="tahun">
                              Tahun
                            </label>
                            <div className="input-group">
                              <select
                                className="form-select text-center"
                                aria-label="Default select example"
                                name="tahun"
                                value={this.state.tahun}
                                onChange={(e) => this.onChange(e)}
                              >
                                <option value="">Pilih Tahun</option>
                                {this.state.pilihan_tahun.map((e, idx) => {
                                  return (
                                    <option value={e} key={idx}>
                                      {e}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>

                          <div className="form-group row my-3">
                            <div className="col-sm-6">
                              <div className="custom-control custom-switch switch-success">
                                <input type="checkbox" className="custom-control-input" id="customSwitchSuccess" />
                                &nbsp;
                                <label className="form-label text-muted" htmlFor="customSwitchSuccess">
                                  Remember me
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6 text-end">
                              <a href="auth-recover-pw.html" className="text-muted font-13">
                                <i className="dripicons-lock"></i> Forgot password?
                              </a>
                            </div>
                          </div>

                          <div className="form-group mb-0 row">
                            <div className="col-12">
                              <button className="btn btn-primary w-100 waves-effect waves-light" type="submit" onClick={(e) => this.onSubmit(e)}>
                                Log In <i className="fas fa-sign-in-alt ms-1"></i>
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="card-body bg-light-alt text-center">
                    <span className="text-muted d-none d-sm-inline-block">Sekretariat Daerah Kabupaten Berau © 2021</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Home);
