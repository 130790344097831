import React, { Component } from "react";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import axios from "axios";
import $ from "jquery";
import "datatables.net-bs5";
import download from "js-file-download";

import "../plugins/datatables/dataTables.bootstrap5.min.css";
import "../plugins/datatables/buttons.bootstrap5.min.css";
import "../plugins/datatables/responsive.bootstrap4.min.css";
import { bulan } from "../../Definisi";
import Cookies from "js-cookie";
import moment from "moment";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opd: [],
      realisasi: [],
      bulan: moment().format("M"),
      id: "",
    };
  }

  onChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name === "bulan") {
          this.tableRender();
        }
      }
    );
  }

  componentDidMount() {
    this.tableRender();
    // console.log(moment().format("M"));
  }

  tableRender() {
    $("#row_callback").DataTable().destroy();
    axios.get(`${process.env.REACT_APP_APIURL}opd/anggaran/${this.state.bulan}/${Cookies.get("tahun")}`).then((response) => {
      this.setState(
        {
          opd: response.data.opd,
          realisasi: response.data.realisasi,
        },
        () => {
          $("#row_callback").DataTable();
        }
      );
    });
  }

  download() {
    axios
      .get(`${process.env.REACT_APP_APIURL}opd/download/${Cookies.get("tahun")}/${this.state.bulan}`, {
        headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
        responseType: "blob",
      })
      .then((response) => {
        let filename = response.headers["content-disposition"].split("filename=")[1].replaceAll('"', "");
        download(response.data, filename);
      });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="page-title-box">
              <div className="row">
                <div className="col">
                  <h4 className="page-title">List Perangkat Daerah</h4>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li className="breadcrumb-item active">List Perangkat Daerah</li>
                  </ol>
                </div>
                <div className="col-auto col-3 align-self-center">
                  <div className="mb-3 row">
                    <label htmlFor="bulan" className="col-sm-4 form-label align-self-center mb-lg-0">
                      Pilih Bulan
                    </label>
                    <div className="col-sm-6">
                      <select name="bulan" className="form-select" aria-label="Default select example" id="bulan" value={this.state.bulan} onChange={(e) => this.onChange(e)}>
                        {bulan.map((e, idx) => {
                          return (
                            <option value={e.id} key={e.id}>
                              {e.nama_bulan}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-sm-2">
                      <button className="btn btn-danger btn-sm add-file" onClick={() => this.download()}>
                        Download
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <table
              id="row_callback"
              className="table table-striped table-bordered dt-responsive nowrap"
              style={{ borderCollapse: "collapse", borderSpacing: 0, width: "100%" }}
            >
              <thead>
                <tr>
                  <th width="3%" rowSpan="3">
                    No
                  </th>
                  <th rowSpan="3">Nama Perangkat Daerah</th>
                  <th className="text-end" rowSpan="3">
                    APBD
                  </th>
                  <th className="text-center" rowSpan="3">
                    APBD-P
                  </th>
                  <th className="text-center" rowSpan="3">
                    Total Anggaran
                  </th>
                  <th colSpan="3" className="text-center">
                    Realisasi {bulan.find((i) => i.id == this.state.bulan).pendek} {Cookies.get("tahun")}
                  </th>
                  <th rowSpan="2" className="text-center">
                    Bobot
                  </th>
                  <th rowSpan="2" className="text-center">
                    TTB
                  </th>
                </tr>
                <tr>
                  <th className="text-center">Fisik</th>
                  <th className="text-center" colSpan="2">
                    Keuangan
                  </th>
                </tr>
                <tr>
                  <th className="text-center">%</th>
                  <th className="text-center">Rp</th>
                  <th className="text-center">%</th>
                  <th className="text-center">%</th>
                  <th className="text-center">%</th>
                </tr>
              </thead>
              <tbody>
                {this.state.opd.map((e, idx) => {
                  let fisik = 0,
                    keuangan = 0,
                    persen_keuangan = 0,
                    bobot = 0,
                    ttb = 0;
                  let realisasi = this.state.realisasi.find((i) => i.realisasi_opd === e.opd_id);
                  if (realisasi) {
                    // realisasi = JSON.parse(realisasi.realisasi_anggaran);
                    fisik = realisasi.realisasi_fisik;
                    keuangan = realisasi.realisasi_keuangan;
                    // fisik = realisasi.reduce((a, b) => +a + (+b.fisik * b.total) / realisasi.find((x) => x.anggaran > 0).anggaran, 0).toFixed(2);
                    // keuangan = realisasi.reduce((a, b) => +a + +b.keuangan, 0);
                    persen_keuangan = ((keuangan / (+e.murni + +e.perubahan)) * 100).toFixed(2);
                  }
                  bobot = (((+e.murni + +e.perubahan) / this.state.opd.reduce((a, b) => +a + +b.murni + +b.perubahan, 0)) * 100).toFixed(2);
                  ttb = ((fisik * bobot) / 100).toFixed(2);
                  return (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>
                        <Link to={"/realisasi-opd/" + e.opd_id}>{e.opd_nama}</Link>
                      </td>
                      <td className="text-end">
                        <NumberFormat value={e.murni} thousandSeparator={true} displayType="text" />
                      </td>
                      <td className="text-end">
                        <NumberFormat value={e.perubahan} thousandSeparator={true} displayType="text" />
                      </td>
                      <td className="text-end">
                        <NumberFormat value={+e.murni + +e.perubahan} thousandSeparator={true} displayType="text" />
                      </td>
                      <td className="text-center">{fisik}</td>
                      <td className="text-end">
                        <NumberFormat value={keuangan} displayType={"text"} thousandSeparator={true} />
                      </td>
                      <td className="text-center">{persen_keuangan}</td>
                      <td className="text-center">{bobot}</td>
                      <td className="text-center">{ttb}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
