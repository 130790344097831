import React, { Component } from "react";
import { Link } from "react-router-dom";

class Breadcumb extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="row">
        <div className="col-sm-12">
          <div className="page-title-box">
            <div className="row">
              <div className="col">
                <h4 className="page-title">{this.props.page_name}</h4>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  {this.props.pages !== undefined
                    ? this.props.pages.map((e, idx) => {
                        return (
                          <li className="breadcrumb-item active" key={idx}>
                            {e}
                          </li>
                        );
                      })
                    : ""}
                </ol>
              </div>
              {this.props.page_url !== undefined ? (
                <div className="col-auto align-self-center">
                  <div className="d-inline-block">
                    <Link className="btn btn-outline-primary btn-sm add-file" to={`/${this.props.page_url}/add`}>
                      <i className="fas fa-plus me-2 "></i>Tambah
                    </Link>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Breadcumb;
