import React, { Component } from "react";
import moment from "moment";
import { Chart as ChartJS } from "chart.js/auto";
import { Chart } from "react-chartjs-2";
import axios from "axios";
import ModalSetKontrak from "./ModalSetKontrak";
import { bulan } from "../../Definisi";

class Grafik extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: "",
      labels: [],
      datasets: [],
    };
    this.setKontrak = this.setKontrak.bind(this);
  }

  setGrafikOptions() {
    this.setState({
      options: {
        responsive: true,
        tooltips: {
          mode: "index",
          intersect: false,
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
        legend: {
          labels: {
            fontColor: "#8997bd",
          },
        },
        scales: {
          //   xAxes: [
          //     {
          //       display: true,
          //       // scaleLabel: {
          //       //     display: true,
          //       //     labelString: 'Month'
          //       // },
          //       gridLines: {
          //         color: "rgba(137, 151, 189, 0.15)",
          //       },
          //       ticks: {
          //         fontColor: "#8997bd",
          //       },
          //     },
          //   ],
          yAxes: [
            {
              //   gridLines: {
              //     color: "rgba(137, 151, 189, 0.15)",
              //   },
              ticks: {
                min: 0,
                max: 100,
                stepSize: 20,
              },
              //   ticks: {
              //     beginAtZero: true,
              //     max: 100,
              //     steps: 10,
              //     stepValue: 5,
              //     // min: 0,
              //     // stepSize: 20,
              //     fontColor: "#8997bd",
              //   },
            },
          ],
        },
      },
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.paket !== this.props.paket) {
      this.setGrafikOptions();
      this.setGrafikData();
    }
  }

  setGrafikData() {
    let labels = [];
    if (this.props.paket.paket_mulai_kontrak) {
      labels = bulan.filter((i) => i.id >= moment(this.props.paket.paket_mulai_kontrak).format("M") && i.id <= moment(this.props.paket.paket_selesai_kontrak).format("M"));
    }
    let temp;
    let fisik = labels.map((e) => {
      let f = this.props.progress.find((i) => i.progress_bulan === e.id);
      if (f) {
        temp = f.progress_fisik;
      }
      return temp;
    });
    temp = 0;
    let keuangan = labels.map((e) => {
      let f = this.props.progress.find((i) => i.progress_bulan === e.id);
      if (f) {
        temp = parseInt((f.progress_keuangan / this.props.paket.paket_nilai) * 100);
      }
      return temp;
    });
    this.setState({
      labels: labels.map((e) => {
        return e.nama_bulan;
      }),
      datasets: [
        {
          label: "Fisik",
          fill: false,
          backgroundColor: "#d9e6fd",
          borderColor: "#d9e6fd",
          data: fisik,
        },
        {
          label: "Keuangan (Dlm Jutaan)",
          fill: false,
          backgroundColor: "#4a8af6",
          borderColor: "#4a8af6",
          borderDash: [5, 5],
          data: keuangan,
        },
      ],
    });
  }

  setKontrak(mulai, selesai) {
    let data = {
      mulai: mulai,
      selesai: selesai,
    };
    axios.put(`${process.env.REACT_APP_APIURL}paket/setkontrak/${this.props.paket.paket_id}`, data).then((response) => {
      this.props.reload();
    });
  }

  componentDidMount() {
    this.setGrafikOptions();
    this.setGrafikData();
  }

  render() {
    return (
      <div className="col-lg-8">
        <div className="card">
          <div className="card-header">
            <div className="row align-items-center">
              <div className="col">
                <h4 className="card-title">Grafik</h4>
              </div>
              <div className="col-auto">
                <div className="dropdown">
                  <a href="#" className="btn btn-sm btn-outline-light dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="mdi mdi-dots-horizontal text-muted"></i>
                  </a>
                  <div className="dropdown-menu dropdown-menu-end">
                    <a className="dropdown-item" href="#">
                      Purchases
                    </a>
                    <a className="dropdown-item" href="#">
                      Emails
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="text-center">
              <Chart
                type="line"
                data={{
                  labels: this.state.labels,
                  datasets: this.state.datasets,
                }}
                height="79px"
              />
              <h6 className="text-primary bg-soft-primary p-3 mb-0">
                <i data-feather="calendar" className="align-self-center icon-xs me-1"></i>
                <a href="#" data-bs-toggle="modal" data-bs-target="#ModalSetKontrak">
                  {this.props.paket.paket_mulai_kontrak
                    ? moment(this.props.paket.paket_mulai_kontrak).format("DD MMM YYYY") + " s/d " + moment(this.props.paket.paket_selesai_kontrak).format("DD MMM YYYY")
                    : "Set Kontrak"}
                </a>
              </h6>
            </div>
          </div>
        </div>
        <ModalSetKontrak paket={this.props.paket} key={this.props.paket.paket_mulai_kontrak} setKontrak={this.setKontrak} />
      </div>
    );
  }
}

export default Grafik;
