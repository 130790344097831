import React, { Component } from "react";

class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="col-lg-4">
        <div className="card">
          <div className="card-header">
            <div className="row align-items-center">
              <div className="col">
                <h4 className="card-title">Gallery</h4>
              </div>
              <div className="col-auto">
                <div className="dropdown">
                  <a href="#" className="btn btn-sm btn-outline-light dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="mdi mdi-dots-horizontal text-muted"></i>
                  </a>
                  <div className="dropdown-menu dropdown-menu-end">
                    <a className="dropdown-item" href="#">
                      Purchases
                    </a>
                    <a className="dropdown-item" href="#">
                      Emails
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="text-center">
              <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                <ol className="carousel-indicators">
                  {this.props.gallery.map((e, idx) => {
                    return <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to={idx} key={idx} className={idx === 0 ? "active" : ""}></li>;
                  })}
                </ol>
                <div className="carousel-inner">
                  {this.props.gallery.map((e, idx) => {
                    return (
                      <div className={`carousel-item ${idx == 0 ? "active" : ""}`} key={idx}>
                        <img
                          src={`${process.env.REACT_APP_APIURL}paket/${e.paket_tahun}/${e.paket_id}/${e.gallery_bulan}/${e.gallery_namafile}`}
                          className="d-block w-100"
                          alt="..."
                          height="335px"
                        />
                      </div>
                    );
                  })}
                </div>
                <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Gallery;
