import feather from "feather-icons";
import App from "./_index";
import Login from "../login/index";
import { withRouter, useHistory } from "react-router-dom";
import Cookies from "js-cookie";

function Index() {
  feather.replace();
  const history = useHistory();
  document.title = process.env.REACT_APP_NAME;
  if (window.location.hash === "#/auth") {
    document.body.classList.remove("dark-sidenav");
    document.body.classList.remove("navy-sidenav");
    document.body.classList.add("account-body");
    document.body.classList.add("accountbg");
    return <Login />;
  } else {
    if (!Cookies.get("nama")) history.push("/auth");
    document.body.classList.remove("account-body");
    document.body.classList.remove("accountbg");
    document.body.classList.add("dark-sidenav");
    document.body.classList.add("navy-sidenav");
    return <App />;
  }
}

export default withRouter(Index);
