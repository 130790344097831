import React, { Component } from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import Cookies from "js-cookie";

import { bulan } from "../../Definisi";

class GrafikRealisasi extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      options: {},
      series: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    // This will erase any local state updates!
    // Do not do this.
    this.renderChart();
  }

  renderChart() {
    let bln = Array.from(Array(12).keys(), (n) => n + 1);

    // let realisasi = this.props.realisasi.map((a) => {
    //   return { bulan: a.realisasi_bulan, anggaran: JSON.parse(a.realisasi_anggaran) };
    // });
    let before = 0;
    let keuangan = bln.map((k) => {
      if (this.props.realisasi.find((i) => i.realisasi_bulan == k)) {
        before = this.props.realisasi.find((i) => i.realisasi_bulan == k).realisasi_keuangan;
        // before = 0;
      }
      return before;
    });

    before = 0;
    let fisik = bln.map((k) => {
      if (this.props.realisasi.find((i) => i.realisasi_bulan == k)) {
        before = this.props.realisasi.find((i) => i.realisasi_bulan == k).realisasi_fisik;
        // before = 0;
      }
      return before;
    });

    this.setState({
      options: {
        chart: {
          stacked: false,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
            autoSelected: "zoom",
          },
        },
        xaxis: {
          type: "month",
          categories: bulan.map((b) => b.pendek),
          axisBorder: {
            show: true,
            color: "#45404a2e",
          },
          axisTicks: {
            show: true,
            color: "#45404a2e",
          },
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#20016c",
            },
            labels: {
              style: {
                color: "#20016c",
              },
              formatter: (value) => {
                return value.toLocaleString();
              },
            },
            title: {
              text: "Keuangan",
            },
          },
          {
            opposite: true,
            // decimalsInFloat: 2,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#fa5c7c",
            },
            labels: {
              style: {
                color: "#fa5c7c",
              },
              formatter: (value) => {
                return value.toFixed(2) + "%";
              },
            },
            title: {
              text: "Fisik",
            },
          },
        ],
        colors: ["#2a77f4", "#a5c2f1"],
        legend: {
          position: "top",
          horizontalAlign: "right",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
          width: [3],
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
      },
      series: [
        {
          name: "Keuangan",
          data: keuangan,
        },
        {
          name: "Fisik",
          data: fisik,
        },
      ],
    });
  }

  componentDidMount() {
    this.renderChart();
  }

  render() {
    return (
      <div className="row">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Grafik Realisasi {this.props.opd.opd_nama + " tahun " + Cookies.get("tahun")}</h4>
          </div>
          <div className="card-body">
            <Chart options={this.state.options} series={this.state.series} height="250" />
          </div>
        </div>
      </div>
    );
  }
}

export default GrafikRealisasi;
