import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import NumberFormat from "react-number-format";
import axios from "axios";

import { bulan } from "../../Definisi";
import Cookies from "js-cookie";
import Breadcumb from "../master/Breadcumb";
import GrafikRealisasi from "./_realisasi";
import ModalHapus from "../master/ModalHapus";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      realisasi: [],
      bagian: {},
    };
  }

  onChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
    });
  }

  componentDidMount() {
    this.tableRender();
  }

  tableRender() {
    let bagian = Cookies.get("bagian");
    let tahun = Cookies.get("tahun");
    if (this.props.match.params.bagian) {
      bagian = this.props.match.params.bagian;
      tahun = this.props.match.params.tahun;
    }
    axios.get(`${process.env.REACT_APP_APIURL}realisasi-bagian/${bagian}/${tahun}`).then((response) => {
      this.setState({
        bagian: response.data.bagian,
        realisasi: response.data.realisasi,
      });
    });
  }

  setItem(e) {
    this.setState({ id: e.realisasi_id });
  }

  onDelete() {
    axios.delete(`${process.env.REACT_APP_APIURL}realisasi-bagian/${this.state.id}`).then((response) => {
      this.tableRender();
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <Breadcumb page_name="Data Realisasi" page_url="realisasi-bagian" pages={["Data Realisasi", "Realisasi"]} />
        <GrafikRealisasi bagian={this.state.bagian} realisasi={this.state.realisasi} key={this.state.realisasi} />
        <div className="row">
          <div className="table-responsive">
            <table className="table table-striped mb-0">
              <thead className="thead-light">
                <tr>
                  <th rowSpan="2" width="5%">
                    No
                  </th>
                  <th rowSpan="2">Bulan</th>
                  <th rowSpan="2" className="text-center">
                    Anggaran
                  </th>
                  <th colSpan="2" className="text-center">
                    Realisasi
                  </th>
                  <th rowSpan="2" className="text-center">
                    Last Update
                  </th>
                  <th rowSpan="2" className="text-center">
                    Action
                  </th>
                </tr>
                <tr>
                  <th className="text-center">Fisik (%)</th>
                  <th className="text-center">Keuangan (Rp)</th>
                </tr>
              </thead>
              <tbody>
                {this.state.realisasi.map((e, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>{bulan.find((a) => a.id == e.realisasi_bulan).nama_bulan}</td>
                      <td className="text-end">
                        <NumberFormat value={e.realisasi_anggaran} displayType={"text"} thousandSeparator={true} />
                      </td>
                      <td className="text-center">{e.realisasi_fisik}</td>
                      <td className="text-end">
                        <NumberFormat value={e.realisasi_keuangan} displayType={"text"} thousandSeparator={true} />
                      </td>
                      <td className="text-center">{moment(e.realisasi_updated).format("DD MMM YYYY HH:mm")}</td>
                      <td className="text-center">
                        <Link to={`/realisasi-bagian/edit/${e.realisasi_id}`}>
                          <i className="las la-pen text-secondary font-16"></i>
                        </Link>
                        <a href="#" data-bs-toggle="modal" data-bs-target="#modalHapus" onClick={() => this.setItem(e)}>
                          <i className="las la-trash-alt text-secondary font-16"></i>
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <ModalHapus onDelete={() => this.onDelete()} />
      </div>
    );
  }
}

export default Home;
