import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import "datatables.net-bs5";

import "../plugins/datatables/dataTables.bootstrap5.min.css";
import "../plugins/datatables/buttons.bootstrap5.min.css";
import "../plugins/datatables/responsive.bootstrap4.min.css";
import Breadcumb from "../master/Breadcumb";
import ModalHapus from "../master/ModalHapus";
import { level } from "../../Definisi";
import Swal from "sweetalert2";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      id: "",
    };
  }

  onDelete() {
    axios.delete(`${process.env.REACT_APP_APIURL}users/${this.state.id}`).then((response) => {
      this.tableRender();
    });
  }

  componentDidMount() {
    this.tableRender();
    if (this.props.location.state) {
      var Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: "success",
        title: "Input Data Success!<br />",
      });
    }
  }

  tableRender() {
    $("#row_callback").DataTable().destroy();
    axios.get(`${process.env.REACT_APP_APIURL}users`).then((response) => {
      this.setState(
        {
          users: response.data.users,
        },
        () => {
          $("#row_callback").DataTable();
        }
      );
    });
  }

  setItem(e) {
    this.setState({ id: e.user_id });
  }

  render() {
    return (
      <div className="container-fluid">
        <Breadcumb page_name="List USERS" page_url="users" pages={["Data Master", "USERS"]} />
        <div className="row">
          <div className="col-12">
            <table
              id="row_callback"
              className="table table-striped table-bordered dt-responsive nowrap"
              style={{ borderCollapse: "collapse", borderSpacing: 0, width: "100%" }}
            >
              <thead>
                <tr>
                  <th width="3%">No</th>
                  <th>Nama</th>
                  <th>Level</th>
                  <th>No HP</th>
                  <th>OPD</th>
                  <th>Status</th>
                  <th width="5%">Action</th>
                </tr>
              </thead>
              <tbody>
                {this.state.users.map((e, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>
                        <img src={process.env.REACT_APP_APIURL + `images/users/user-4.jpg`} alt="" className="rounded-circle thumb-xs me-1" />
                        {e.user_nama}
                      </td>
                      <td>{level[e.user_level]}</td>
                      <td>{e.user_nohp}</td>
                      <td>{e.opd_nama}</td>
                      <td>{e.user_active == 1 ? "Aktif" : "Non Aktif"}</td>
                      <td className="text-center">
                        <Link to={"/users/edit/" + e.user_id}>
                          <i className="las la-pen text-secondary font-16 me-2"></i>
                        </Link>
                        <a href="#" data-bs-toggle="modal" data-bs-target="#modalHapus" onClick={() => this.setItem(e)}>
                          <i className="las la-trash-alt text-secondary font-16"></i>
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <ModalHapus onDelete={() => this.onDelete()} />
      </div>
    );
  }
}

export default withRouter(Home);
