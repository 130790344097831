import React, { Component } from "react";
import SimpleBar from "simplebar-react";

class NotificationBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="dropdown-menu dropdown-menu-end dropdown-lg pt-0">
        <h6 className="dropdown-item-text font-15 m-0 py-3 border-bottom d-flex justify-content-between align-items-center">
          Notifications <span className="badge bg-primary rounded-pill">2</span>
        </h6>
        <SimpleBar style={{ maxHeight: "220px" }}>
          <a href="#" className="dropdown-item py-3">
            <small className="float-end text-muted ps-2">2 min ago</small>
            <div className="media">
              <div className="avatar-md bg-soft-primary">
                <i data-feather="shopping-cart" className="align-self-center icon-xs"></i>
              </div>
              <div className="media-body align-self-center ms-2 text-truncate">
                <h6 className="my-0 fw-normal text-dark">Your order is placed</h6>
                <small className="text-muted mb-0">Dummy text of the printing and industry.</small>
              </div>
            </div>
          </a>

          <a href="#" className="dropdown-item py-3">
            <small className="float-end text-muted ps-2">10 min ago</small>
            <div className="media">
              <div className="avatar-md bg-soft-primary">
                <img src={`${process.env.REACT_APP_APIURL}images/users/user-4.jpg`} alt="" className="thumb-sm rounded-circle" />
              </div>
              <div className="media-body align-self-center ms-2 text-truncate">
                <h6 className="my-0 fw-normal text-dark">Meeting with designers</h6>
                <small className="text-muted mb-0">It is a long established fact that a reader.</small>
              </div>
            </div>
          </a>

          <a href="#" className="dropdown-item py-3">
            <small className="float-end text-muted ps-2">40 min ago</small>
            <div className="media">
              <div className="avatar-md bg-soft-primary">
                <i data-feather="users" className="align-self-center icon-xs"></i>
              </div>
              <div className="media-body align-self-center ms-2 text-truncate">
                <h6 className="my-0 fw-normal text-dark">UX 3 Task complete.</h6>
                <small className="text-muted mb-0">Dummy text of the printing.</small>
              </div>
            </div>
          </a>

          <a href="#" className="dropdown-item py-3">
            <small className="float-end text-muted ps-2">1 hr ago</small>
            <div className="media">
              <div className="avatar-md bg-soft-primary">
                <img src={`${process.env.REACT_APP_APIURL}images/users/user-5.jpg`} alt="" className="thumb-sm rounded-circle" />
              </div>
              <div className="media-body align-self-center ms-2 text-truncate">
                <h6 className="my-0 fw-normal text-dark">Your order is placed</h6>
                <small className="text-muted mb-0">It is a long established fact that a reader.</small>
              </div>
            </div>
          </a>

          <a href="#" className="dropdown-item py-3">
            <small className="float-end text-muted ps-2">2 hrs ago</small>
            <div className="media">
              <div className="avatar-md bg-soft-primary">
                <i data-feather="check-circle" className="align-self-center icon-xs"></i>
              </div>
              <div className="media-body align-self-center ms-2 text-truncate">
                <h6 className="my-0 fw-normal text-dark">Payment Successfull</h6>
                <small className="text-muted mb-0">Dummy text of the printing.</small>
              </div>
            </div>
          </a>
        </SimpleBar>
        <a href="#" className="dropdown-item text-center text-primary">
          View all <i className="fi-arrow-right"></i>
        </a>
      </div>
    );
  }
}

export default NotificationBar;
