import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Cookies from "js-cookie";

// import { withRouter } from "react-router-dom";
import "../assets/css/bootstrap.min.css";
import "../assets/css/icons.min.css";
import "../assets/css/metisMenu.min.css";
import "../assets/css/app.min.css";

import $ from "jquery";
import feather from "feather-icons";
import "bootstrap/dist/js/bootstrap.bundle.min";
import metisMenu from "metismenu";

import RouteLink, { route_extra } from "../../Route";
import Footer from "./_footer";
import Menu from "./_menu";
import Topbar from "./_topbar";
import Dashboard from "../dashboard/index";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // this.initMetisMenu();
    this.initMetisMenu();
    this.initEnlarge();
    this.initLeftMenuCollapse();
    feather.replace();
  }

  initMetisMenu() {
    //metis menu
    $(".metismenu").metisMenu();
    let _this = this;
    $(window).resize(function () {
      _this.initEnlarge();
    });
  }

  initEnlarge() {
    if ($(window).width() < 1300) {
      $("body").addClass("enlarge-menu enlarge-menu-all");
    } else {
      // if ($('body').data('keep-enlarged') != true)
      $("body").removeClass("enlarge-menu enlarge-menu-all");
    }
  }

  initLeftMenuCollapse() {
    // Left menu collapse
    $(".button-menu-mobile").on("click", function (event) {
      event.preventDefault();
      $("body").toggleClass("enlarge-menu");
    });
  }

  render() {
    return (
      <>
        <Menu />

        <div className="page-wrapper">
          <Topbar />
          <div className="page-content">
            <Switch>
              {RouteLink.map((e, index) => {
                if (e.subMenus != undefined) {
                  return e.subMenus.map((subMenu, i) => {
                    return <Route path={subMenu.path} exact component={subMenu.component} key={subMenu.path} />;
                  });
                } else return <Route exact path={e.path} component={e.component} key={e.path} />;
              })}
              {route_extra.map((e, index) => {
                return <Route exact path={e.path} component={e.component} key={e.path} />;
              })}
              <Route component={Dashboard} />
            </Switch>
            <Footer />
          </div>
        </div>
      </>
    );
  }
}

export default App;
