export const level = ["Super Admin", "Admin OPD", "Operator"];
export const bulan = [
  { id: 1, nama_bulan: "Januari", pendek: "Jan" },
  { id: 2, nama_bulan: "Februari", pendek: "Feb" },
  { id: 3, nama_bulan: "Maret", pendek: "Mar" },
  { id: 4, nama_bulan: "April", pendek: "Apr" },
  { id: 5, nama_bulan: "Mei", pendek: "Mei" },
  { id: 6, nama_bulan: "Juni", pendek: "Jun" },
  { id: 7, nama_bulan: "Juli", pendek: "Jul" },
  { id: 8, nama_bulan: "Agustus", pendek: "Agt" },
  { id: 9, nama_bulan: "September", pendek: "Sep" },
  { id: 10, nama_bulan: "Oktober", pendek: "Okt" },
  { id: 11, nama_bulan: "November", pendek: "Nov" },
  { id: 12, nama_bulan: "Desember", pendek: "Des" },
];

export const jenis_pekerjaan = [
  { id: 0, name: "Pengadaan Barang" },
  // { id: 1, name: "Jasa Konsultansi Badan Usaha Non Konstruksi" },
  { id: 1, name: "Jasa Konsultansi" },
  { id: 2, name: "Pekerjaan Konstruksi" },
  { id: 3, name: "Jasa Lainnya" },
  // { id: 4, name: "Jasa Konsultansi Perorangan" },
  { id: 4, name: "Jasa Konsultansi" },
  { id: 5, name: "Jasa Konsultansi" },
  // { id: 5, name: "Jasa Konsultansi Badan Usaha Konstruksi" },
];

export const pembulatan = function (angka) {
  if (angka >= 1000000000000) return (angka / 1000000000000).toFixed(2) + " T";
  else if (angka >= 1000000000) return (angka / 1000000000).toFixed(2) + " M";
  else if (angka >= 1000000) return (angka / 1000000).toFixed(2) + " jt";
  else return angka;
};
