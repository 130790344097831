import React, { Component } from "react";
import moment from "moment";

class ModalSetKontrak extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mulai: "",
      selesai: "",
      paket: "",
    };
  }

  onChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
    });
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (nextProps.paket !== prevState.paket) {
  //     return { paket: nextProps.paket };
  //   }
  // }

  // componentDidUpdate(prevProps) {
  //   console.log("ubah1", prevProps);
  //   if (prevProps.paket !== this.props.paket) {
  //     console.log("ubah2");
  //     this.setState({
  //       mulai: moment(this.props.paket.paket_mulai_kontrak).format("YYYY-MM-DD"),
  //       selesai: moment(this.props.paket.paket_selesai_kontrak).format("YYYY-MM-DD"),
  //     });
  //   }
  // }

  ubahValue() {
    this.setState({
      mulai: moment(this.props.paket.paket_mulai_kontrak).format("YYYY-MM-DD"),
      selesai: moment(this.props.paket.paket_selesai_kontrak).format("YYYY-MM-DD"),
    });
  }

  componentDidMount() {
    this.ubahValue();
  }

  render() {
    return (
      <div className="modal fade" id="ModalSetKontrak" tabIndex="-1" role="dialog" aria-labelledby="exampleModalDefaultLogin" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h6 className="modal-title m-0" id="exampleModalDefaultLogin">
                Set Tanggal Kontrak
              </h6>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="card-body p-0 auth-header-box">
                <div className="text-center">
                  <h4 className="mt-1 mb-1 fw-semibold font-18">Tanggal Kontrak</h4>
                  <p className="text-muted  mb-0">Silahkan Lengkapi Data Berikut</p>
                </div>
              </div>
              <div className="card-body p-0">
                <form className="form-horizontal auth-form" action="index.html">
                  <div className="form-group mb-2">
                    <label className="form-label" htmlFor="Mulai">
                      Mulai
                    </label>
                    <div className="input-group">
                      <input
                        type="date"
                        className="form-control"
                        name="mulai"
                        id="Mulai"
                        value={this.state.mulai}
                        placeholder="Pilih Tanggal"
                        onChange={(e) => this.onChange(e)}
                      />
                    </div>
                  </div>

                  <div className="form-group mb-2">
                    <label className="form-label" htmlFor="selesai">
                      Selesai
                    </label>
                    <div className="input-group">
                      <input
                        type="date"
                        className="form-control"
                        name="selesai"
                        id="selesai"
                        value={this.state.selesai}
                        placeholder="Pilih Tanggal"
                        onChange={(e) => this.onChange(e)}
                      />
                    </div>
                  </div>

                  <div className="form-group mb-0 row mt-3">
                    <div className="col-12">
                      <button
                        className="btn btn-primary w-100 waves-effect waves-light"
                        type="button"
                        data-bs-dismiss="modal"
                        onClick={() => this.props.setKontrak(this.state.mulai, this.state.selesai)}
                      >
                        Simpan <i className="fas fa-sign-in-alt ms-1"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalSetKontrak;
