import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Cookies from "js-cookie";
import RouteLink from "../../Route";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="left-sidenav">
        <div className="brand">
          <a href="/" className="logo">
            <span>
              <img src={`${process.env.REACT_APP_APIURL}images/logo-sm.png`} alt="logo-small" className="logo-sm" />
            </span>
            <span>
              <img src={`${process.env.REACT_APP_APIURL}images/logo.png`} alt="logo-large" className="logo-lg logo-light" />
            </span>
          </a>
        </div>
        <div className="menu-content h-100" data-simplebar>
          <ul className="metismenu left-sidenav-menu">
            {RouteLink.map((e, index) => {
              if (e.path === "pemisah") {
                return <hr className="hr-dashed hr-menu" key={index} />;
              } else if (e.path === "label") {
                return (
                  <li className="menu-label mt-0" key={index}>
                    {e.name}
                  </li>
                );
              } else if (e.subMenus === undefined) {
                if (e.auth.includes("-1") || e.auth.includes(Cookies.get("level"))) {
                  return (
                    <li key={index}>
                      <Link to={e.path}>
                        <i data-feather={e.icon} className="align-self-center menu-icon"></i>
                        <span>{e.name}</span>
                      </Link>
                    </li>
                  );
                }
              } else {
                if (e.auth.includes("-1") || e.auth.includes(Cookies.get("level"))) {
                  return (
                    <li key={index}>
                      <a href="#">
                        <i data-feather={e.icon} className="align-self-center menu-icon"></i>
                        <span>{e.name}</span>
                        <span className="menu-arrow">
                          <i className="mdi mdi-chevron-right"></i>
                        </span>
                      </a>
                      <ul className="nav-second-level" aria-expanded="false">
                        {e.subMenus.map((subMenu, i) => {
                          if (subMenu.auth.includes("-1") || subMenu.auth.includes(Cookies.get("level"))) {
                            return (
                              <li key={i}>
                                <Link to={subMenu.path}>
                                  <i className="ti-control-record"></i>
                                  {subMenu.name}
                                </Link>
                              </li>
                            );
                          }
                        })}
                      </ul>
                    </li>
                  );
                }
              }
            })}
          </ul>
        </div>
      </div>
    );
  }
}

export default withRouter(Menu);
