import React, { Component } from "react";
import NumberFormat from "react-number-format";

class AnggaranTerbesar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="col-lg-6">
        <div className="card">
          <div className="card-header">
            <div className="row align-items-center">
              <div className="col">
                <h4 className="card-title">OPD Anggaran Terbesar</h4>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table mb-0">
                <thead className="table-light">
                  <tr>
                    <th className="border-top-0">No</th>
                    <th className="border-top-0">OPD</th>
                    <th className="border-top-0 text-end">Total Anggaran</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.opd.map((e, idx) => {
                    if (idx < 10)
                      return (
                        <tr key={idx}>
                          <td>{idx + 1}</td>
                          <td>{e.opd_nama}</td>
                          <td className="text-end">
                            <NumberFormat value={e.total} displayType={"text"} thousandSeparator={true} />
                          </td>
                        </tr>
                      );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AnggaranTerbesar;
