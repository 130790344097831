import React, { Component } from "react";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <footer className="footer text-center text-sm-start">
        Copyright &copy; 2021 Tim IT Bag. Pembangunan Setda Berau
        <span className="text-muted d-none d-sm-inline-block float-end">
          Crafted with <i className="mdi mdi-heart text-danger"></i> by Teramedia
        </span>
      </footer>
    );
  }
}

export default Footer;
