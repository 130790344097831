import React, { Component } from "react";
import NumberFormat from "react-number-format";
import axios from "axios";
import Breadcumb from "../master/Breadcumb";
import feather from "feather-icons";
import Cookies from "js-cookie";
import AnggaranTerbesar from "./_anggaranTerbesar";
import RealisasiTerendah from "./_realisasiTerendah";
import Belanja from "./_belanja";
import Realisasi from "./_realisasi";
import Grafik from "./_grafik";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opd: [],
      realisasi: [],
      anggaran: [],
      // gallery: [],
    };
  }

  async isiRealisasi(data, opd) {
    let realisasi_opd = [];
    for await (let e of opd) {
      // console.log(e);
      let fisik = 0,
        keuangan = 0,
        persen_keuangan = 0;
      // bobot = 0,
      // ttb = 0;
      let realisasi = data.find((i) => i.realisasi_opd === e.opd_id);
      if (e.total > 0) {
        if (realisasi) {
          // realisasi = JSON.parse(realisasi.realisasi_anggaran);
          fisik = realisasi.realisasi_fisik;
          keuangan = realisasi.realisasi_keuangan;
          persen_keuangan = ((keuangan / e.total) * 100).toFixed(2);
          // bobot = (((+e.murni + +e.perubahan) / opd.reduce((a, b) => +a + +b.murni + +b.perubahan, 0)) * 100).toFixed(2);
          // ttb = ((fisik * bobot) / 100).toFixed(2);
        }
        realisasi_opd.push({
          opd_id: e.opd_id,
          opd_nama: e.opd_nama,
          total_apbd: e.total,
          fisik: fisik,
          keuangan: keuangan,
          persen_keuangan: persen_keuangan,
        });
      }
    }
    // realisasi_opd.sort((a, b) => a.persen_keuangan - b.persen_keuangan);
    realisasi_opd.sort((a, b) => a.fisik - b.fisik);
    this.setState({ realisasi: realisasi_opd });
    // console.log("realisasi", realisasi_opd);
    return true;
  }

  componentDidMount() {
    axios.get(`${process.env.REACT_APP_APIURL}dashboard/${Cookies.get("tahun")}`, { headers: { Authorization: "Bearer " + Cookies.get("auth-token") } }).then((response) => {
      // console.log(response);
      this.isiRealisasi(response.data.realisasi, response.data.opd);
      // });
      // console.log(realisasi_opd);
      // console.log(await this.isiRealisasi(response.data.realisasi, response.data.opd));
      this.setState({
        opd: response.data.opd,
        anggaran: response.data.anggaran,
        // gallery: response.data.gallery,
        // realisasi: response.data.realisasi,
      });
    });
    feather.replace();
  }

  render() {
    return (
      <div className="container-fluid">
        <Breadcumb />

        <div className="row">
          <div className="col-lg-9">
            <Grafik opd={this.state.opd} key={this.state.opd} />
          </div>

          <div className="col-lg-3">
            <div className="card overflow-hidden">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <div className="media">
                      <img src={`${process.env.REACT_APP_APIURL}images/money-beg.png`} alt="" className="align-self-center" height="40" />
                      <div className="media-body align-self-center ms-3">
                        <h6 className="m-0 font-20">
                          <NumberFormat value={this.state.opd.reduce((a, b) => +a + +b.total, 0)} displayType={"text"} thousandSeparator={true} />
                        </h6>
                        <p className="text-muted mb-0">Total APBD</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="apexchart-wrapper">
                    <div id="dash_spark_1" className="chart-gutters"></div>
                  </div>
                </div>
              </div>
            </div>
            <Realisasi realisasi={this.state.realisasi} />
          </div>
        </div>

        <div className="row">
          <AnggaranTerbesar opd={this.state.opd} />
          <RealisasiTerendah realisasi={this.state.realisasi} />
        </div>
      </div>
    );
  }
}

export default Home;
