import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import Breadcumb from "../master/Breadcumb";
import { level } from "../../Definisi";
import Swal from "sweetalert2";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      nama: "",
      nip: "",
      username: "",
      password: "",
      password_ulangi: "",
      level: "",
      email: "",
      nohp: "",
      jk: "",
      jabatan: "",
      active: 1,
      pilihan_opd: [],
      foto: "",
    };
  }

  onChange(e) {
    const name = e.target.name;
    const tipe = e.target.type;
    let value = e.target.value;
    if (tipe === "checkbox") {
      value = e.target.checked ? 1 : 0;
    }

    if (tipe === "file") {
      value = e.target.files[0];
      let size = value.size / 1024 / 1024; //dalam mb
      if (size > 1) {
        alert("Ukuran File tidak boleh lebih dari 1Mb");
        return false;
      }
    }
    this.setState({
      [name]: value,
    });
  }

  componentDidMount() {
    axios.get(`${process.env.REACT_APP_APIURL}users/${this.props.match.params.id}`).then((response) => {
      this.setState({
        pilihan_opd: response.data.opd,
      });
      if (response.data.users) {
        this.setState({
          id: response.data.users.user_id,
          nama: response.data.users.user_nama,
          nip: response.data.users.user_nip,
          opd: response.data.users.user_opd,
          username: response.data.users.user_name,
          level: response.data.users.user_level,
          email: response.data.users.user_email,
          nohp: response.data.users.user_nohp,
          jk: response.data.users.user_jk,
          jabatan: response.data.users.user_jabatan,
          active: response.data.users.user_active,
        });
      }
    });
  }

  onSubmit() {
    const formData = new FormData();
    formData.append("nama", this.state.nama);
    formData.append("nip", this.state.nip);
    formData.append("opd", this.state.opd);
    formData.append("username", this.state.username);
    formData.append("level", this.state.level);
    formData.append("email", this.state.email);
    formData.append("nohp", this.state.nohp);
    formData.append("jk", this.state.jk);
    formData.append("jabatan", this.state.jabatan);
    formData.append("active", this.state.active);
    formData.append("password", this.state.password);
    formData.append("password_ulangi", this.state.password_ulangi);
    formData.append("foto", this.state.foto);

    if (this.state.id === "") {
      axios
        .post(process.env.REACT_APP_APIURL + "users", formData)
        .then((response) => {
          this.props.history.push("/users");
        })
        .catch((reason) => {
          var Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });

          Toast.fire({
            icon: "error",
            title: "Input Data Error!<br />" + reason.response.data.message,
          });
        });
    } else {
      axios
        .put(process.env.REACT_APP_APIURL + "users/" + this.props.match.params.id, formData)
        .then((response) => {
          this.props.history.push({ pathname: "/users", state: { data: "success" } });
        })
        .catch((reason) => {
          var Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });

          Toast.fire({
            icon: "error",
            title: "Input Data Error!<br />" + reason.response.data.message,
          });
        });
    }
  }

  render() {
    return (
      <div className="container-fluid">
        <Breadcumb page_name="Users" pages={["Data Master", this.state.mode == "add" ? "Form Tambah User" : "Form Edit User"]} />
        <div className="row">
          <div className="col-lg-10">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Form Users</h4>
                <p className="text-muted mb-0">Silahkan lengkapi form berikut</p>
              </div>
              <div className="card-body">
                <div className="general-label">
                  <form onSubmit={() => this.onSubmit()}>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label className="form-label" htmlFor="username">
                            Nama
                          </label>
                          <input type="text" className="form-control" name="nama" value={this.state.nama} onChange={(e) => this.onChange(e)} required="" />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label className="form-label" htmlFor="useremail">
                            NIP
                          </label>
                          <input type="text" className="form-control" name="nip" value={this.state.nip} onChange={(e) => this.onChange(e)} required="" />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="mb-3">
                          <label className="form-label" htmlFor="subject">
                            Jabatan
                          </label>
                          <input type="text" className="form-control" name="jabatan" value={this.state.jabatan} onChange={(e) => this.onChange(e)} required="" />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label className="form-label" htmlFor="username">
                            Email
                          </label>
                          <input type="email" className="form-control" name="email" value={this.state.email} onChange={(e) => this.onChange(e)} required="" />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label className="form-label" htmlFor="useremail">
                            No HP
                          </label>
                          <input type="email" className="form-control" name="nohp" value={this.state.nohp} onChange={(e) => this.onChange(e)} required="" />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label className="form-label" htmlFor="subject">
                            Jenis Kelamin
                          </label>
                          <div className="col-md-9 mt-1">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="jk"
                                id="inlineRadio1"
                                value="L"
                                onChange={(e) => this.onChange(e)}
                                checked={this.state.jk == "L"}
                              />
                              <label className="form-check-label" htmlFor="inlineRadio1">
                                Laki-Laki
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="jk"
                                id="inlineRadio2"
                                value="P"
                                onChange={(e) => this.onChange(e)}
                                checked={this.state.jk == "P"}
                              />
                              <label className="form-check-label" htmlFor="inlineRadio2">
                                Perempuan
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="mb-3">
                          <label className="form-label" htmlFor="subject">
                            Foto
                          </label>
                          <input type="file" className="form-control" name="foto" required="" onChange={(e) => this.onChange(e)} />
                        </div>
                      </div>
                    </div>
                    <hr className="hr-dashed" />
                    <div className="mb-3 row">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="username">
                              Username
                            </label>
                            <input type="text" className="form-control" name="username" value={this.state.username} onChange={(e) => this.onChange(e)} required="" />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="useremail">
                              Level
                            </label>
                            <select className="form-select" aria-label="Default select example" name="level" value={this.state.level} onChange={(e) => this.onChange(e)}>
                              <option value="">Pilih Level</option>
                              {level.map((e, idx) => {
                                return (
                                  <option value={idx} key={idx}>
                                    {e}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="subject">
                              OPD
                            </label>
                            <select className="form-select" aria-label="Default select example" name="opd" value={this.state.opd} onChange={(e) => this.onChange(e)}>
                              <option value="">Pilih OPD</option>
                              {this.state.pilihan_opd.map((e, idx) => {
                                return (
                                  <option value={e.opd_id} key={idx}>
                                    {e.opd_nama}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="username">
                              Password
                            </label>
                            <input type="password" className="form-control" name="password" value={this.state.password} onChange={(e) => this.onChange(e)} required="" />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label className="form-label" htmlFor="useremail">
                              Ulangi Password
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              name="password_ulangi"
                              value={this.state.password_ulangi}
                              onChange={(e) => this.onChange(e)}
                              required=""
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="merk" className="col-sm-2 form-label align-self-center mb-lg-0">
                            Active
                          </label>
                          <div className="col-sm-10">
                            <div className="form-check form-switch mt-2">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="active"
                                id="flexSwitchCheckDefault"
                                checked={this.state.active}
                                onChange={(e) => this.onChange(e)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="ms-auto">
                        <button type="button" className="btn btn-primary" onClick={() => this.onSubmit()}>
                          Simpan
                        </button>
                        &nbsp;
                        <Link to="/users" className="btn btn-danger">
                          Batal
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Form);
