import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import $ from "jquery";
import "datatables.net-bs5";
import NumberFormat from "react-number-format";
import { jenis_pekerjaan } from "../../Definisi";

import "../plugins/datatables/dataTables.bootstrap5.min.css";
import "../plugins/datatables/buttons.bootstrap5.min.css";
import "../plugins/datatables/responsive.bootstrap4.min.css";
// import Breadcumb from "../master/Breadcumb";
import ModalHapus from "../master/ModalHapus";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paket: [],
      opd: [],
      kecamatan: [],
      id: "",
      selected_opd: Cookies.get("opd_spse"),
    };
  }

  onChange(e) {
    const nama = e.target.name;
    const value = e.target.value;
    if (nama === "selected_opd") {
      this.setState(
        {
          [nama]: value,
        },
        () => {
          this.tableRender();
        }
      );
    } else {
      this.setState({
        [nama]: value,
      });
    }
  }

  onDelete() {
    axios.delete(`${process.env.REACT_APP_APIURL}paket/${this.state.id}`, { headers: { Authorization: "Bearer " + Cookies.get("auth-token") } }).then((response) => {
      this.tableRender();
    });
  }

  componentDidMount() {
    axios.get(`${process.env.REACT_APP_APIURL}opd`).then((response) => {
      this.setState({ opd: response.data.opd });
    });
    axios.get(`${process.env.REACT_APP_APIURL}kecamatan`).then((response) => {
      this.setState({ kecamatan: response.data.kecamatan }, () => this.tableRender());
    });
  }

  synchronize() {
    axios.get(`${process.env.REACT_APP_APIURL}paket/synchronize`, { headers: { Authorization: "Bearer " + Cookies.get("auth-token") } }).then((response) => {
      setTimeout(() => this.tableRender(), 3000);
    });
  }

  tableRender() {
    $("#row_callback").DataTable().destroy();
    axios.get(`${process.env.REACT_APP_APIURL}paket/${this.state.selected_opd}`, { headers: { Authorization: "Bearer " + Cookies.get("auth-token") } }).then((response) => {
      this.setState(
        {
          paket: response.data.paket,
        },
        () => {
          $("#row_callback").DataTable();
        }
      );
    });
  }

  menuOpdAdmin() {
    if (Cookies.get("level") == "0") {
      return (
        <>
          <label htmlFor="bulan" className="col-sm-4 form-label align-self-center mb-lg-0">
            OPD
          </label>
          <div className="col-sm-8">
            <select
              name="selected_opd"
              className="form-select"
              aria-label="Default select example"
              id="opd"
              value={this.state.selected_opd}
              onChange={(e) => this.onChange(e)}
            >
              {this.state.opd.map((e, idx) => {
                return (
                  <option value={e.opd_spse} key={idx}>
                    {e.opd_nama}
                  </option>
                );
              })}
            </select>
          </div>
        </>
      );
    }
  }

  setItem(e) {
    this.setState({ id: e.paket_id });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="page-title-box">
              <div className="row">
                <div className="col">
                  <h4 className="page-title">List Paket Pekerjaan</h4>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="#">Progress</a>
                    </li>
                    <li className="breadcrumb-item active">List Paket</li>
                  </ol>
                </div>
                <div className="col-auto col-6 align-self-center">
                  <div className="row text-end">
                    <div className="col-10 row">{this.menuOpdAdmin()}</div>
                    <div className="col-2">
                      <button className="btn btn-outline-primary btn-sm add-file" onClick={() => this.synchronize()}>
                        <i className="fas fa-sync me-2 "></i>Synchronize
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <table id="row_callback" className="table table-striped table-bordered dt-responsive" style={{ borderCollapse: "collapse", borderSpacing: 0, width: "100%" }}>
              <thead>
                <tr>
                  <th rowSpan="2" width="3%">
                    No
                  </th>
                  <th rowSpan="2">Nama Paket</th>
                  <th rowSpan="2">Jenis Pekerjaan</th>
                  <th rowSpan="2" width="10%">
                    Kecamatan
                  </th>
                  <th rowSpan="2" width="15%">
                    Pemenang
                  </th>
                  <th rowSpan="2" width="10%">
                    Nilai Kontrak
                  </th>
                  <th width="15%" colSpan="2" className="text-center">
                    Progress
                  </th>
                </tr>
                <tr>
                  <th className="text-center">Fisik</th>
                  <th className="text-center">Keuangan</th>
                </tr>
              </thead>
              <tbody>
                {this.state.paket.map((e, idx) => {
                  let lokasi = e.paket_lokasi.split(",");
                  // console.log(lokasi);
                  return (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>
                        <Link to={"progress/detail/" + e.paket_id}>{e.paket_nama}</Link>
                      </td>
                      <td>{jenis_pekerjaan.find((i) => i.id === e.paket_jenis).name}</td>
                      <td>
                        {lokasi.map((i, index) => {
                          let x = this.state.kecamatan.find((obj) => {
                            return obj.kecamatan_id == i;
                          });
                          if (index > 0) {
                            return ", " + x.kecamatan_nama;
                          } else {
                            return x.kecamatan_nama;
                          }
                        })}
                      </td>
                      <td>{e.paket_pemenang}</td>
                      <td className="text-end">
                        <NumberFormat value={e.paket_nilai} displayType={"text"} thousandSeparator={true} />
                      </td>
                      <td className="text-center">{e.fisik}</td>
                      <td className="text-end">
                        <NumberFormat value={e.keuangan} displayType={"text"} thousandSeparator={true} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <ModalHapus onDelete={() => this.onDelete()} />
      </div>
    );
  }
}

export default Home;
