import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import Breadcumb from "../master/Breadcumb";
// import { level } from "../../Definisi";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      apbd: 0,
      apbdp: 0,
      total: 0,
      mode: "add",
    };
  }

  onChange(e) {
    const name = e.target.name;
    const tipe = e.target.type;
    let value = e.target.value;
    if (tipe === "checkbox") {
      value = e.target.checked ? 1 : 0;
    }

    if (tipe === "file") {
      value = e.target.files[0];
      let size = value.size / 1024 / 1024; //dalam mb
      if (size > 1) {
        alert("Ukuran File tidak boleh lebih dari 1Mb");
        return false;
      }
    }
    this.setState({
      [name]: value,
    });
  }

  componentDidMount() {
    axios.get(`${process.env.REACT_APP_APIURL}anggaran`, { headers: { Authorization: "Bearer " + Cookies.get("auth-token") } }).then((response) => {
      if (response.data.anggaran.length > 0) {
        // console.log(response);
        this.setState({
          apbd: response.data.anggaran[0].anggaran_murni,
          apbdp: response.data.anggaran[0].anggaran_perubahan,
          total: +response.data.anggaran[0].anggaran_murni + +response.data.anggaran[0].anggaran_perubahan,
          mode: "edit",
        });
      }
    });
  }

  info(pesan, mode = "error") {
    var Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });

    Toast.fire({
      icon: mode,
      title: pesan,
    });
  }

  onSubmit() {
    let formData = {
      apbd: this.state.apbd,
      apbdp: this.state.apbdp,
    };
    axios
      .post(process.env.REACT_APP_APIURL + "anggaran", formData, { headers: { Authorization: "Bearer " + Cookies.get("auth-token") } })
      .then((response) => {
        this.info("Input Data Success", "success");
      })
      .catch((reason) => {
        this.info("Input Data Error!<br />" + reason.response.data.message);
      });
  }

  render() {
    return (
      <div className="container-fluid">
        <Breadcumb page_name="Anggaran" pages={["Data Anggaran", this.state.mode == "add" ? "Form Tambah Anggaran" : "Form Edit Anggaran"]} />
        <div className="row">
          <div className="col-lg-4">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Form Anggaran</h4>
                <p className="text-muted mb-0">Silahkan lengkapi form berikut</p>
              </div>
              <div className="card-body">
                <div className="general-label">
                  <form onSubmit={() => this.onSubmit()}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label className="form-label" htmlFor="username">
                            APBD
                          </label>
                          <NumberFormat
                            thousandSeparator={true}
                            value={this.state.apbd}
                            className="form-control"
                            onValueChange={(values) => {
                              const { formattedValue, value } = values;
                              this.setState({ apbd: value, total: +this.state.apbdp + +value });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label className="form-label" htmlFor="apbdp">
                            APBD-P
                          </label>
                          <NumberFormat
                            thousandSeparator={true}
                            value={this.state.apbdp}
                            className="form-control"
                            onValueChange={(values) => {
                              const { formattedValue, value } = values;
                              this.setState({ apbdp: value, total: +this.state.apbd + +value });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label className="form-label" htmlFor="total">
                            TOTAL
                          </label>
                          <NumberFormat thousandSeparator={true} value={this.state.total} className="form-control" readOnly />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="ms-auto">
                        <button type="button" className="btn btn-primary" onClick={() => this.onSubmit()}>
                          Simpan
                        </button>
                        &nbsp;
                        <Link to="/users" className="btn btn-danger">
                          Batal
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Form);
