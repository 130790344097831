import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import Breadcumb from "../master/Breadcumb";
// import { level } from "../../Definisi";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: undefined,
      nama: "",
      users: [],
      selected_user: "",
      mode: "add",
    };
  }

  onChange(e) {
    const name = e.target.name;
    let value = e.target.value;

    this.setState({
      [name]: value,
    });
  }

  componentDidMount() {
    axios
      .get(`${process.env.REACT_APP_APIURL}users/bagian`, {
        headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
      })
      .then((response) => {
        this.setState({
          users: response.data.users,
        });
      });
    if (this.props.match.params.id) {
      axios
        .get(`${process.env.REACT_APP_APIURL}bagian/${this.props.match.params.id}`, {
          headers: { Authorization: "Bearer " + Cookies.get("auth-token") },
        })
        .then((response) => {
          this.setState({
            id: response.data.bagian.bagian_id,
            nama: response.data.bagian.bagian_nama,
            selected_user: response.data.bagian.bagian_user,
          });
        });
    }
  }

  info(pesan, mode = "error") {
    var Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });

    Toast.fire({
      icon: mode,
      title: pesan,
    });
  }

  onSubmit() {
    let formData = {
      id: this.state.id,
      nama: this.state.nama,
      user: this.state.selected_user,
    };
    axios
      .post(process.env.REACT_APP_APIURL + "bagian", formData, { headers: { Authorization: "Bearer " + Cookies.get("auth-token") } })
      .then((response) => {
        this.props.history.push("/bagian");
      })
      .catch((reason) => {
        this.info("Input Data Error!<br />" + reason.response.data.data.message);
      });
  }

  render() {
    return (
      <div className="container-fluid">
        <Breadcumb page_name="Bagian" pages={["Data Bagian", this.state.mode == "add" ? "Form Tambah Bagian" : "Form Edit Bagian"]} />
        <div className="row">
          <div className="col-lg-4">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Form Bagian</h4>
                <p className="text-muted mb-0">Silahkan lengkapi form berikut</p>
              </div>
              <div className="card-body">
                <div className="general-label">
                  <form onSubmit={() => this.onSubmit()}>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label className="form-label" htmlFor="nama">
                            Nama Bidang / Bagian
                          </label>
                          <input type="text" name="nama" className="form-control" value={this.state.nama} onChange={(e) => this.onChange(e)} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="mb-3">
                          <label className="form-label" htmlFor="username">
                            User
                          </label>
                          <select
                            name="selected_user"
                            className="form-select"
                            aria-label="Default select example"
                            value={this.state.selected_user}
                            onChange={(e) => this.onChange(e)}
                          >
                            <option value="">Pilih User</option>
                            {this.state.users.map((e, idx) => {
                              return (
                                <option value={e.user_id} key={e.user_id}>
                                  {e.user_nama}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="ms-auto">
                        <button type="button" className="btn btn-primary" onClick={() => this.onSubmit()}>
                          Simpan
                        </button>
                        &nbsp;
                        <Link to="/bagian" className="btn btn-danger">
                          Batal
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Form);
