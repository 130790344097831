import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Breadcumb from "../master/Breadcumb";
import "../plugins/dropify/css/dropify.min.css";
import $ from "jquery";
import "dropify/dist/js/dropify.min";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paket: "",
      keuangan: "",
      kendala: "",
      fisik: "",
      file1: "",
      file2: "",
      file3: "",
      file4: "",
      mode: "add",
    };
  }

  onChange(e) {
    const name = e.target.name;
    const tipe = e.target.type;
    let value = e.target.value;
    if (tipe === "checkbox") {
      value = e.target.checked;
    }
    if (tipe === "file") {
      value = e.target.files[0];
      let size = value.size / 1024 / 1024; //dalam mb
      if (size > 1) {
        alert("Ukuran File tidak boleh lebih dari 1Mb");
        return false;
      }
    }
    this.setState({
      [name]: value,
    });
  }

  componentDidMount() {
    axios.get(`${process.env.REACT_APP_APIURL}progress/detail/${this.props.match.params.id}/${this.props.match.params.bulan}`).then((response) => {
      if (response.data.progress.length > 0) {
        let prog = response.data.progress[0];
        let lokasi = `${process.env.REACT_APP_APIURL}paket/${this.props.match.params.tahun}/${this.props.match.params.id}/${this.props.match.params.bulan}/`;
        let file1 = response.data.gallery.find((a) => a.gallery_susunan === 1) ? lokasi + response.data.gallery.find((a) => a.gallery_susunan === 1).gallery_namafile : "";
        let file2 = response.data.gallery.find((a) => a.gallery_susunan === 2) ? lokasi + response.data.gallery.find((a) => a.gallery_susunan === 2).gallery_namafile : "";
        let file3 = response.data.gallery.find((a) => a.gallery_susunan === 3) ? lokasi + response.data.gallery.find((a) => a.gallery_susunan === 3).gallery_namafile : "";
        let file4 = response.data.gallery.find((a) => a.gallery_susunan === 4) ? lokasi + response.data.gallery.find((a) => a.gallery_susunan === 4).gallery_namafile : "";
        this.setState(
          {
            keuangan: prog.progress_keuangan,
            fisik: prog.progress_fisik,
            kendala: prog.progress_kendala,
            file1: file1,
            file2: file2,
            file3: file3,
            file4: file4,
            mode: "edit",
          },
          () => $(".dropify").dropify()
        );
      } else {
        $(".dropify").dropify();
      }
    });
  }

  onSubmit() {
    const formData = new FormData();
    formData.append("paket", this.props.match.params.id);
    formData.append("tahun", this.props.match.params.tahun);
    formData.append("bulan", this.props.match.params.bulan);
    formData.append("fisik", this.state.fisik);
    formData.append("keuangan", this.state.keuangan);
    formData.append("kendala", this.state.kendala);
    formData.append("file1", this.state.file1);
    formData.append("file2", this.state.file2);
    formData.append("file3", this.state.file3);
    formData.append("file4", this.state.file4);
    formData.append("mode", this.state.mode);

    axios
      .post(process.env.REACT_APP_APIURL + "progress", formData, { headers: { Authorization: "Bearer " + Cookies.get("auth-token") } })
      .then((response) => {
        this.props.history.push("/progress/detail/" + this.props.match.params.id);
      })
      .catch((reason) => {
        var Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });

        Toast.fire({
          icon: "error",
          title: "Input Data Error!<br />" + reason.response.data.message,
        });
      });
  }

  render() {
    return (
      <div className="container-fluid">
        <Breadcumb page_name="Edit Progress" pages={["Progress", "Form Edit Progress"]} />
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Form Progress</h4>
                <p className="text-muted mb-0">Silahkan lengkapi form berikut</p>
              </div>
              <div className="card-body">
                <div className="general-label">
                  <form onSubmit={() => this.onSubmit()}>
                    <div className="mb-3 row">
                      <label htmlFor="nama" className="col-sm-2 form-label align-self-center mb-lg-0">
                        Keuangan
                      </label>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          name="keuangan"
                          placeholder="realisasi keuangan"
                          value={this.state.keuangan}
                          onChange={(e) => this.onChange(e)}
                        />
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label htmlFor="spse" className="col-sm-2 form-label align-self-center mb-lg-0">
                        Fisik
                      </label>
                      <div className="col-sm-6">
                        <input type="number" className="form-control" name="fisik" placeholder="realisasi fisik" value={this.state.fisik} onChange={(e) => this.onChange(e)} />
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label htmlFor="kendala" className="col-sm-2 form-label align-self-center mb-lg-0">
                        Kendala
                      </label>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          name="kendala"
                          placeholder="masukkan jika ada kendala"
                          value={this.state.kendala}
                          onChange={(e) => this.onChange(e)}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xl-3">
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-title">File Upload 1</h4>
                          </div>
                          <div className="card-body">
                            <input
                              name="file1"
                              type="file"
                              onChange={(e) => this.onChange(e)}
                              data-max-file-size="5M"
                              className="dropify"
                              data-default-file={this.state.file1}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-xl-3">
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-title">File Upload 2</h4>
                          </div>
                          <div className="card-body">
                            <input
                              type="file"
                              name="file2"
                              className="dropify"
                              onChange={(e) => this.onChange(e)}
                              data-max-file-size="5M"
                              data-default-file={this.state.file2}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3">
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-title">File Upload 3</h4>
                          </div>
                          <div className="card-body">
                            <input
                              type="file"
                              name="file3"
                              className="dropify"
                              onChange={(e) => this.onChange(e)}
                              data-max-file-size="5M"
                              data-default-file={this.state.file3}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3">
                        <div className="card">
                          <div className="card-header">
                            <h4 className="card-title">File Upload 4</h4>
                          </div>
                          <div className="card-body">
                            <input
                              type="file"
                              name="file4"
                              className="dropify"
                              onChange={(e) => this.onChange(e)}
                              data-max-file-size="5M"
                              data-default-file={this.state.file4}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12 ms-auto">
                        <button type="button" className="btn btn-primary" onClick={() => this.onSubmit()}>
                          Simpan
                        </button>
                        &nbsp;
                        <Link to={`/progress/detail/${this.props.match.params.id}`} className="btn btn-danger">
                          Batal
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Form;
