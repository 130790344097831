import Dashboard from "./pages/dashboard/index";
import MasterOpd from "./pages/master_opd/index";
import MasterOpdForm from "./pages/master_opd/form";
import Opd from "./pages/opd/index";
import Bagian from "./pages/bagian/index";
import BagianForm from "./pages/bagian/form";
// import Belanja from "./pages/belanja/index";
import BelanjaForm from "./pages/belanja/form";
import Users from "./pages/users/index";
import UsersForm from "./pages/users/form";
import Anggaran from "./pages/anggaran/index";
import AnggaranBagian from "./pages/anggaran/bagian";
import Realisasi from "./pages/Realisasi/index";
import RealisasiForm from "./pages/Realisasi/form";
import RealisasiBagian from "./pages/RealisasiBagian/index";
import RealisasiBagianForm from "./pages/RealisasiBagian/form";
import Progress from "./pages/Progress/index";
import ProgressForm from "./pages/Progress/form";
import ProgressDetail from "./pages/Progress/detail";
// import RealisasiEdit from "./pages/Realisasi/edit";
import Logout from "./pages/login/logout";

const routes = [
  {
    path: "label",
    component: Dashboard,
    name: "Main",
    icon: "star",
    auth: ["-1"],
  },
  {
    path: "/dashboard",
    component: Dashboard,
    name: "Dashboard",
    icon: "home",
    auth: ["-1"],
  },
  {
    path: "/opd",
    component: Opd,
    name: "Perangkat Daerah",
    icon: "sliders",
    auth: ["0"],
  },
  {
    path: "/bagian",
    component: Bagian,
    name: "Bidang / Bagian",
    icon: "command",
    auth: ["0", "1"],
  },
  {
    path: "/progress",
    component: Progress,
    name: "Progress",
    icon: "percent",
    auth: ["-1"],
  },
  {
    path: "/realisasi",
    component: Realisasi,
    name: "Realisasi",
    icon: "activity",
    auth: ["0", "1"],
  },
  {
    path: "/realisasi-bagian",
    component: RealisasiBagian,
    name: "Realisasi",
    icon: "activity",
    auth: ["2"],
  },
  {
    path: "/anggaran",
    component: Anggaran,
    name: "Data Anggaran",
    icon: "dollar-sign",
    auth: ["0", "1"],
  },
  {
    path: "/anggaran_bagian",
    component: AnggaranBagian,
    name: "Data Anggaran",
    icon: "dollar-sign",
    auth: ["2"],
  },
  {
    path: "pemisah",
    component: Dashboard,
    name: "-",
    icon: "star",
    auth: ["-1"],
  },
  {
    path: "label",
    component: Dashboard,
    name: "Menu Admin",
    icon: "star",
    auth: ["0"],
  },
  {
    path: "/master",
    component: Dashboard,
    name: "Data Master",
    icon: "grid",
    auth: ["0"],
    subMenus: [
      {
        path: "/master/opd",
        component: MasterOpd,
        name: "OPD",
        icon: "terminal",
        auth: ["0"],
      },
      // {
      //   path: "/master/belanja",
      //   component: Belanja,
      //   name: "Belanja",
      //   icon: "terminal",
      //   auth: ["0"],
      // },
    ],
  },
  {
    path: "/users",
    component: Users,
    name: "Users",
    icon: "users",
    auth: ["0"],
  },
  {
    path: "/settings",
    component: Dashboard,
    name: "Settings",
    icon: "settings",
    auth: ["0"],
  },
  {
    path: "/logout",
    component: Logout,
    name: "Logout",
    icon: "lock",
    auth: ["-1"],
  },
];

export const route_extra = [
  {
    path: "/opd/add",
    component: MasterOpdForm,
    name: "Add OPD",
    auth: ["-1"],
  },
  {
    path: "/opd/edit/:id",
    component: MasterOpdForm,
    name: "Edit OPD",
    auth: ["-1"],
  },
  {
    path: "/belanja/add",
    component: BelanjaForm,
    name: "Add Belanja",
    auth: ["-1"],
  },
  {
    path: "/belanja/edit/:id",
    component: BelanjaForm,
    name: "Edit Belanja",
    auth: ["-1"],
  },
  {
    path: "/users/add",
    component: UsersForm,
    name: "Add Users",
    auth: ["-1"],
  },
  {
    path: "/users/edit/:id",
    component: UsersForm,
    name: "Edit Users",
    auth: ["-1"],
  },
  {
    path: "/progress/add",
    component: ProgressForm,
    name: "Add progress",
    auth: ["-1"],
  },
  {
    path: "/progress/edit/:id/:tahun/:bulan",
    component: ProgressForm,
    name: "Edit progress",
    auth: ["-1"],
  },
  {
    path: "/progress/detail/:id",
    component: ProgressDetail,
    name: "Detail progress",
    auth: ["-1"],
  },
  {
    path: "/realisasi/add",
    component: RealisasiForm,
    name: "Add realisasi",
    auth: ["-1"],
  },
  {
    path: "/realisasi/edit/:id",
    component: RealisasiForm,
    name: "Edit realisasi",
    auth: ["-1"],
  },
  {
    path: "/realisasi-opd/:id",
    component: Realisasi,
    name: "Realisasi",
    icon: "activity",
    auth: ["0", "1"],
  },
  {
    path: "/realisasi-bagian/add",
    component: RealisasiBagianForm,
    name: "Add realisasi",
    auth: ["-1"],
  },
  {
    path: "/realisasi-bagian/edit/:id",
    component: RealisasiBagianForm,
    name: "Edit realisasi",
    auth: ["-1"],
  },
  {
    path: "/realisasibagian/:bagian/:tahun",
    component: RealisasiBagian,
    name: "Edit realisasi",
    auth: ["-1"],
  },
  {
    path: "/bagian/add",
    component: BagianForm,
    name: "Add Bagian",
    auth: ["-1"],
  },
  {
    path: "/bagian/edit/:id",
    component: BagianForm,
    name: "Edit Bagian",
    auth: ["-1"],
  },
];

export default routes;
