import React, { Component } from "react";
// import NumberFormat from "react-number-format";
import { pembulatan } from "../../Definisi";

class Gallery extends Component {
  constructor(props) {
    console.log(props);
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="card">
        <div className="card-header">
          <div className="row align-items-center">
            <div className="col">
              <h4 className="card-title">Realisasi</h4>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="text-center">
            <h6 className="m-0 font-20">
              {pembulatan(this.props.realisasi.reduce((a, b) => +a + +b.keuangan, 0)) +
                ` (${Math.round((this.props.realisasi.reduce((a, b) => +a + +b.keuangan, 0) / this.props.realisasi.reduce((a, b) => +a + +b.total_apbd, 0)) * 100)}%)`}
            </h6>
            <p className="text-muted mb-0">Keuangan</p>
            <h6 className="m-0 font-20">
              {Math.round(this.props.realisasi.reduce((a, b) => +a + +((b.fisik * b.total_apbd) / this.props.realisasi.reduce((a, b) => +a + +b.total_apbd, 0)), 0))}%
            </h6>
            <p className="text-muted mb-0">Fisik</p>
          </div>
        </div>
      </div>
    );
  }
}

export default Gallery;
