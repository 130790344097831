import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
// import Cookies from "js-cookie";
// import $ from "jquery";
import feather from "feather-icons";
import { bulan, jenis_pekerjaan } from "../../Definisi";
// import "datatables.net-bs5";
import NumberFormat from "react-number-format";
import moment from "moment";

// import "../plugins/datatables/dataTables.bootstrap5.min.css";
// import "../plugins/datatables/buttons.bootstrap5.min.css";
// import "../plugins/datatables/responsive.bootstrap4.min.css";
// import "../plugins/chartjs/chart.min.js";
import ModalSetKontrak from "./ModalSetKontrak";
import PerBulan from "./_perBulan";
import Grafik from "./_grafik";
import Gallery from "./_gallery";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paket: "",
      kecamatan: [],
      progress: [],
      gallery: [],
    };
    this.reload = this.reload.bind(this);
  }

  onChange(e) {
    const nama = e.target.name;
    const value = e.target.value;

    this.setState({
      [nama]: value,
    });
  }

  componentDidMount() {
    this.reload();
  }

  reload() {
    axios.get(`${process.env.REACT_APP_APIURL}paket/detail/${this.props.match.params.id}`).then((response) => {
      this.setState({
        paket: response.data.paket,
        kecamatan: response.data.kecamatan,
        progress: response.data.progress,
        gallery: response.data.gallery,
      });
    });
    feather.replace();
  }

  perBulan() {
    if (this.state.paket.paket_mulai_kontrak && this.state.paket.paket_selesai_kontrak) {
      return <PerBulan progress={this.state.progress} paket={this.state.paket} />;
    }
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="page-title-box">
              <div className="row">
                <div className="col">
                  <h4 className="page-title">{this.state.paket.opd_nama}</h4>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/progress">Progress</Link>
                    </li>
                    <li className="breadcrumb-item active">{this.state.paket.paket_nama}</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-6 col-lg-3">
            <div className="card report-card">
              <div className="card-body">
                <div className="row d-flex justify-content-center">
                  <div className="col">
                    <p className="text-dark mb-1 font-weight-semibold">Pemenang</p>
                    <h3 className="m-0">{this.state.paket.paket_pemenang}</h3>
                  </div>
                  <div className="col-auto align-self-center">
                    <div className="report-main-icon bg-light-alt">
                      <i data-feather="star" className="align-self-center text-muted icon-md"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="card report-card">
              <div className="card-body">
                <div className="row d-flex justify-content-center">
                  <div className="col">
                    <p className="text-dark mb-1 font-weight-semibold">Jenis Pekerjaan</p>
                    <h3 className="m-0">
                      {jenis_pekerjaan.find((i) => i.id === this.state.paket.paket_jenis) ? jenis_pekerjaan.find((i) => i.id === this.state.paket.paket_jenis).name : ""}
                    </h3>
                  </div>
                  <div className="col-auto align-self-center">
                    <div className="report-main-icon bg-light-alt">
                      <i data-feather="check-square" className="align-self-center text-muted icon-md"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="card report-card">
              <div className="card-body">
                <div className="row d-flex justify-content-center">
                  <div className="col">
                    <p className="text-dark mb-1 font-weight-semibold">Lokasi</p>
                    <h3 className="m-0">
                      {this.state.paket.paket_lokasi
                        ? this.state.paket.paket_lokasi.split(",").map((e, index) => {
                            let x = this.state.kecamatan.find((obj) => {
                              return obj.kecamatan_id == e;
                            });
                            if (index > 0) {
                              return ", " + x.kecamatan_nama;
                            } else {
                              return x.kecamatan_nama;
                            }
                          })
                        : ""}
                    </h3>
                  </div>
                  <div className="col-auto align-self-center">
                    <div className="report-main-icon bg-light-alt">
                      <i data-feather="map-pin" className="align-self-center text-muted icon-md"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="card report-card">
              <div className="card-body">
                <div className="row d-flex justify-content-center">
                  <div className="col">
                    <p className="text-dark mb-1 font-weight-semibold">Nilai Kontrak</p>
                    <h3 className="m-0">
                      <NumberFormat value={this.state.paket.paket_nilai} displayType={"text"} thousandSeparator={true} />
                    </h3>
                  </div>
                  <div className="col-auto align-self-center">
                    <div className="report-main-icon bg-light-alt">
                      <i data-feather="dollar-sign" className="align-self-center text-muted icon-md"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <Gallery gallery={this.state.gallery} />
          <Grafik paket={this.state.paket} reload={this.reload} progress={this.state.progress} key={this.state.paket} />
        </div>

        {this.perBulan()}
      </div>
    );
  }
}

export default Home;
