import React, { Component } from "react";
import NumberFormat from "react-number-format";

class Belanja extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="row">
        <div className="col-12 col-lg-6 col-xl">
          <div className="card">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col text-center">
                  <span className="h4">
                    <NumberFormat
                      value={this.props.anggaran.filter((i) => i.anggaran_belanja === 5).reduce((a, b) => +a + (+b.anggaran_murni + +b.anggaran_perubahan), 0)}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </span>
                  <h6 className="text-uppercase text-muted mt-2 m-0">Belanja Pegawai</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 col-xl">
          <div className="card">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col text-center">
                  <span className="h4">
                    <NumberFormat
                      value={this.props.anggaran.filter((i) => i.anggaran_belanja === 6).reduce((a, b) => +a + (+b.anggaran_murni + +b.anggaran_perubahan), 0)}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </span>
                  <h6 className="text-uppercase text-muted mt-2 m-0">Belanja Barang dan Jasa</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 col-xl">
          <div className="card">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col text-center">
                  <span className="h4">
                    <NumberFormat
                      value={this.props.anggaran.filter((i) => i.belanja_parent === 2).reduce((a, b) => +a + (+b.anggaran_murni + +b.anggaran_perubahan), 0)}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </span>
                  <h6 className="text-uppercase text-muted mt-2 m-0">Belanja Modal</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6 col-xl">
          <div className="card">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col text-center">
                  <span className="h4">
                    <NumberFormat
                      value={this.props.anggaran.filter((i) => i.anggaran_belanja === 13).reduce((a, b) => +a + (+b.anggaran_murni + +b.anggaran_perubahan), 0)}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </span>
                  <h6 className="text-uppercase text-muted mt-2 m-0">Belanja Hibah</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Belanja;
