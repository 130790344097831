import React, { Component } from "react";
import { bulan, jenis_pekerjaan } from "../../Definisi";
import NumberFormat from "react-number-format";
import moment from "moment";
import { Link } from "react-router-dom";

class PerBulan extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let mulai = moment(this.props.paket.paket_mulai_kontrak).format("M");
    let selesai = moment(this.props.paket.paket_selesai_kontrak).format("M");
    let nomor = 1;
    return (
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <div className="row align-items-center">
                <div className="col">
                  <h4 className="card-title">Progress Per Bulan</h4>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover mb-0">
                  <thead className="thead-light">
                    <tr>
                      <th width="5%" rowSpan="2">
                        No
                      </th>
                      <th rowSpan="2">Bulan</th>
                      <th rowSpan="2">Last Update</th>
                      <th colSpan="2" className="text-center">
                        Keuangan
                      </th>
                      <th rowSpan="2">Fisik</th>
                    </tr>
                    <tr>
                      <th className="text-center">Rp</th>
                      <th className="text-center">%</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bulan.map((e, idx) => {
                      if (e.id >= mulai && e.id <= selesai) {
                        let p = this.props.progress.find((i) => i.progress_bulan === e.id);
                        return (
                          <tr key={idx}>
                            <td>{nomor++}</td>
                            <td>
                              <Link to={`/progress/edit/${this.props.paket.paket_id}/${this.props.paket.paket_tahun}/${e.id}`}>{e.nama_bulan}</Link>
                            </td>
                            <td>{p ? moment(p.progress_updated).format("DD MMM YYYY HH:mm") : "-"}</td>
                            <td className="text-end">
                              <NumberFormat value={p ? p.progress_keuangan : "-"} displayType={"text"} thousandSeparator={true} />
                            </td>
                            <td className="text-center">{p ? ((p.progress_keuangan / this.props.paket.paket_nilai) * 100).toFixed(2) : "-"}</td>
                            <td>
                              <small className="float-end ms-2 pt-1 font-10">{p ? p.progress_fisik : "-"}%</small>
                              <div className="progress mt-2" style={{ height: "3px" }}>
                                <div
                                  className="progress-bar bg-success"
                                  role="progressbar"
                                  style={{ width: p ? p.progress_fisik : "-" }}
                                  aria-valuenow="92"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PerBulan;
