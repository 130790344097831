import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Breadcumb from "../master/Breadcumb";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pilihan_belanja: [],
      parent: "",
      nama: "",
      mode: "add",
    };
  }

  onChange(e) {
    const name = e.target.name;
    const tipe = e.target.type;
    let value = e.target.value;

    this.setState({
      [name]: value,
    });
  }

  componentDidMount() {
    axios.get(`${process.env.REACT_APP_APIURL}belanja/parent`).then((response) => {
      this.setState({
        pilihan_belanja: response.data.belanja,
      });
    });
    if (this.props.match.params.id) {
      axios.get(`${process.env.REACT_APP_APIURL}belanja/${this.props.match.params.id}`).then((response) => {
        this.setState({
          nama: response.data.belanja.belanja_nama,
          parent: response.data.belanja.belanja_parent,
          mode: "edit",
        });
      });
    }
  }

  onSubmit() {
    let formData = {
      nama: this.state.nama,
      parent: this.state.parent,
    };

    if (this.state.mode === "add") {
      axios.post(process.env.REACT_APP_APIURL + "belanja", formData).then((response) => {
        this.props.history.push("/master/belanja");
      });
    } else if (this.state.mode === "edit") {
      axios.put(process.env.REACT_APP_APIURL + "belanja/" + this.props.match.params.id, formData).then((response) => {
        this.props.history.push("/master/belanja");
      });
    }
  }

  render() {
    return (
      <div className="container-fluid">
        <Breadcumb page_name="BELANJA" pages={["Data Master", this.state.mode == "add" ? "Form Tambah BELANJA" : "Form Edit BELANJA"]} />
        <div className="row">
          <div className="col-lg-6">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Form BELANJA</h4>
                <p className="text-muted mb-0">Silahkan lengkapi form berikut</p>
              </div>
              <div className="card-body">
                <div className="general-label">
                  <form onSubmit={() => this.onSubmit()}>
                    <div className="mb-3 row">
                      <label htmlFor="parent" className="col-sm-2 form-label align-self-center mb-lg-0">
                        Parent
                      </label>
                      <div className="col-sm-10">
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          name="parent"
                          value={this.state.parent}
                          onChange={(e) => this.onChange(e)}
                          required
                        >
                          <option value="">Pilih parent</option>
                          <option value="0">Root</option>
                          {this.state.pilihan_belanja.map((e, idx) => {
                            return (
                              <option value={e.belanja_id} key={idx}>
                                {e.belanja_nama}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="mb-3 row">
                      <label htmlFor="nama" className="col-sm-2 form-label align-self-center mb-lg-0">
                        Nama
                      </label>
                      <div className="col-sm-10">
                        <input
                          type="text"
                          className="form-control"
                          name="nama"
                          placeholder="Masukkan nama belanja"
                          value={this.state.nama}
                          onChange={(e) => this.onChange(e)}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-10 ms-auto">
                        <button type="button" className="btn btn-primary" onClick={() => this.onSubmit()}>
                          Simpan
                        </button>
                        &nbsp;
                        <Link to="/master/belanja" className="btn btn-danger">
                          Batal
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Form;
